import { PropagateLoader } from "react-spinners";

export const PageLoader = ({ pageLoading }) => {
  const loaderWrapper = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "99999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  };

  return (
    <PropagateLoader
      color="#20adf8"
      loading={pageLoading}
      cssOverride={loaderWrapper}
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};
