import React from "react";
import LedgerReportService from "./LedgerReportService";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";

const LedgerReportList = () => {
  const {
    data,
    columns,
    getLedgerList,
    fromDate,
    toDate,
    handleFromDateChange,
    handleToDateChange,
  } = LedgerReportService();

  // useEffect(() => {
  //   document.getElementById("btnSubmit").click();
  // }, []);

  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body ">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>User Ledger</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            Ledger Report
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="header loan-application">
                  <div class="row">
                    <div class="col-md-9">
                      <h2>
                        <strong>Ledger Report</strong>
                      </h2>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-2">
                          <label className="lbl" htmlFor="fromDate">
                            From Date
                          </label>
                          <DatePicker
                            type="date"
                            className="form-control form-selection"
                            id="fromDate"
                            name="fromDate"
                            selected={fromDate}
                            onChange={handleFromDateChange}
                            dateFormat="dd MMMM yyyy"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-2" id="asdf">
                          <label className="lbl" htmlFor="toDate">
                            To Date
                          </label>
                          <DatePicker
                            type="date"
                            className="form-control form-selection"
                            id="toDate"
                            name="toDate"
                            selected={toDate}
                            onChange={handleToDateChange}
                            dateFormat="dd MMMM yyyy"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-2">
                        <div className="mb-2">
                          <label className="lbl" htmlFor="txtSearch">
                            Search
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="txtSearch"
                              name="search"
                              placeholder="Search Here"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                  //   onClick={() => {
                                  //     getLedgerReport();
                                  //   }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-2">
                        <div className="">
                          <div className="input-group">
                            <div className="input-group-append">
                              <button
                                className="btn btn-primary"
                                id="btnSubmit"
                                onClick={getLedgerList}
                              >
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    keyField="objectId"
                    responsive
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20]}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 215px)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LedgerReportList;
