import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RdPlanServices from "./RdPlanServices";

const RDPlanAdd = ({ setPageLoading }) => {

  const {
    handleSubmit,
    input,
    handleChange,
    errorObj,
    isLoading
  } = RdPlanServices(setPageLoading);

  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Rd Plan</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">Rd Plan</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="authentication no-authentication cus-height">
            <div className="col-md-12 content-center content-center-sign-up">
              <div className="row clearfix mb-4">
                <div className="col-lg-12 m-auto">
                  <div className="card-plain card-plain-sign-up">
                    <Form
                      onSubmit={handleSubmit}
                      className="form-div"
                      id="category-form"
                    >
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Name : </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={input?.name}
                              onChange={handleChange}
                              placeholder="Name"
                            />
                            <span>{errorObj?.name}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Duration (In Months) : </Form.Label>
                            <Form.Control
                              type="text"
                              name="duration"
                              value={input?.duration}
                              onChange={handleChange}
                              placeholder="Duration"
                            />
                            <span>{errorObj?.duration}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Maturity Amount : </Form.Label>
                            <Form.Control
                              type="text"
                              name="maturityAmount"
                              value={input?.maturityAmount}
                              onChange={handleChange}
                              placeholder="₹ Maturity Amount"
                            />
                            <span>{errorObj?.maturityAmount}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Premium : </Form.Label>
                            <Form.Control
                              type="text"
                              name="premium"
                              value={input?.premium}
                              onChange={handleChange}
                              placeholder="₹ Premium"
                            />
                            <span>{errorObj?.premium}</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/RdPlanList"
                          className="btn btn-danger btn-round mr-2"
                        >
                          Cancel
                        </Link>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-round btn-width"
                          disabled={isLoading}
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RDPlanAdd;