import React from "react";
import { Link } from "react-router-dom";
import RangeWiseFeeService from "./RangeWiseFeeService";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect } from "react";

const EditRangeFee = ({ setPageLoading }) => {
  const { input, errorObj, handleChange, handleSubmit, rangeWiseFeeById, isLoading } =
    RangeWiseFeeService(setPageLoading);
  useEffect(() => {
    rangeWiseFeeById();
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Add Processing Fees</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            Add Processing Fees
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                        <Link to="/">
                          <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                            Processing Fees List
                          </button>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="authentication no-authentication cus-height">
            <div className="col-md-12 content-center content-center-sign-up">
              <div className="row clearfix mb-4">
                <div className="col-lg-12 m-auto">
                  <div className="card-plain card-plain-sign-up">
                    <Form
                      onSubmit={handleSubmit}
                      className="form-div"
                      id="category-form"
                    >
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Minimum Amount : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.minAmount}
                              name="minAmount"
                              type="text"
                              placeholder="Please Enter Minimum Amount"
                            />
                            <span>{errorObj?.minAmount}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Maximum Amount : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.maxAmount}
                              name="maxAmount"
                              type="text"
                              placeholder="Please Enter Maximum Amount"
                            />
                            <span>{errorObj?.maxAmount}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Processing Fee : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.processingFee}
                              name="processingFee"
                              type="text"
                              placeholder="Please Enter Processing Fee"
                            />
                            <span>{errorObj?.processingFee}</span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/GetRangeWiseFee"
                          className="btn btn-danger btn-round mr-2"
                        >
                          Cancel
                        </Link>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-round btn-width"
                          disabled={isLoading}
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditRangeFee;
