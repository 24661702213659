import { useState, useEffect } from "react";
import { Capitalize } from "../../../../Services/Common";
import { postRequest } from "../../../../Services/apiServices";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../Services/Validation";
import { useLocation, useNavigate } from "react-router-dom";

const SignupService = () => {
  var initialSate = {
    emailId: "",
    franchiseName: "",
    address: "",
    pinCode: "",
    landMark: "",
    location: "",
    gst: "",
    pan: "",
    mobileNumber: "",
    contactPerson: "",
  };
  const { state } = useLocation();
  const { franchiseData } = state || {};
  const navigate = useNavigate();
  const [errorObj, setErrorObj] = useState({});
  const [inputValues, setInputValues] = useState(initialSate);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });
    if (name === "emailId" && value) {
      if (!isValidEmail(value)) {
        setErrorObj((old) => {
          return {
            ...old,
            [name]: "Please enter a valid email.",
          };
        });
      }
    }

    setInputValues((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      let obj = { ...inputValues };

      let hasError = false;
      // Check all form fields for errors
      Object.keys(inputValues).forEach((name) => {
        const value = inputValues[name];
        if (!value) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });
      // If there are errors, prevent form submission
      if (hasError || errorObj.emailId) {
        return;
      }

      if (obj?.password !== obj?.confirmPassword) {
        setErrorObj({ confirmPassword: "Passwords do not match" });
        return;
      }
      let position = {}
      try {
        position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      } catch (err) {
        console.log("geolocation error: " + err);
      }
      const { latitude, longitude } = position?.coords || {};

      // if (!latitude || !longitude) {
      //   return toast.error("Location access is required to proceed.", {
      //     toastId: "invalid-location",
      //   });
      // }
      obj.lattitute = latitude?.toString() || "0";
      obj.longitute = longitude?.toString() || "0";

      let url = "User/RegisterFranchise";
      if (franchiseData) {
        url = "User/UpdateFranchiseDetails";
      }
      setIsLoading(true);
      const res = await postRequest(url, obj);

      if (res?.statusCode === 1) {
        toast.success(res?.responseText || "Successfully registered.");
        navigate("/User-list");
      } else {
        toast.error(res?.responseText || "Franchise Registration failed.", {
          toastId: "Failed to Register",
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("error==>", e);
      if (e.message === "User denied Geolocation") {
        return toast.error("Location access is required to proceed.", {
          toastId: "invalid-location",
        });
      }
      toast.error("Franchise Registration failed.", {
        toastId: "Failed to Register",
      });
    }
  };

  useEffect(() => {
    if (franchiseData) {
      setInputValues({
        emailId: franchiseData.emailId,
        franchiseName: franchiseData.franchiseName,
        address: franchiseData.address,
        pinCode: franchiseData.pinCode,
        landMark: franchiseData.landMark,
        location: franchiseData.location,
        gst: franchiseData.gst,
        pan: franchiseData.pan,
        mobileNumber: franchiseData.mobileNumber,
        contactPerson: franchiseData.contactPerson,
        userID: franchiseData.userID,
      });
    } else {
      setInputValues(initialSate);
    }
  }, [franchiseData]);
  return {
    handleChange,
    handleSignUp,
    inputValues,
    errorObj,
    franchiseData,
    isLoading
  };
};

export default SignupService;
