import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect } from "react";
import SlabSettingService from "./SlabSettingService";

const EditSlabSetting = ({ setPageLoading }) => {
  const { input, errorObj, handleChange, handleSubmit, slabSettingById, isLoading, masterSlab } =
    SlabSettingService({setPageLoading});
  useEffect(() => {
    slabSettingById();
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Add Slab Setting</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            Add Slab Setting
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="authentication no-authentication cus-height">
            <div className="col-md-12 content-center content-center-sign-up">
              <div className="row clearfix mb-4">
                <div className="col-lg-12 m-auto">
                  <div className="card-plain card-plain-sign-up">
                    <Form
                      onSubmit={handleSubmit}
                      className="form-div"
                      id="slab-form"
                    >
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Level : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.level}
                              name="level"
                              as="select"
                            >
                              <option value="" disabled>Select Level</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </Form.Control>
                            <span>{errorObj?.level}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Slab : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.slabId}
                              name="slabId"
                              as="select"
                            >
                              <option value="" disabled>Select Slab</option>
                              {
                                masterSlab?.map((slab) => (
                                  <option value={slab.id} key={slab.id}>{slab.name}</option>
                                ))
                              }
                            </Form.Control>
                            <span>{errorObj?.slabId}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Start : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.start}
                              name="start"
                              type="text"
                              placeholder="Please Enter Start"
                            />
                            <span>{errorObj?.start}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>End : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.end}
                              name="end"
                              type="text"
                              placeholder="Please Enter End"
                            />
                            <span>{errorObj?.end}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>From Month : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.fromMonth}
                              name="fromMonth"
                              type="text"
                              placeholder="Please Enter From Month"
                            />
                            <span>{errorObj?.fromMonth}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>To Month : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.toMonth}
                              name="toMonth"
                              type="text"
                              placeholder="Please Enter To Month"
                            />
                            <span>{errorObj?.toMonth}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Amount : </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              value={input?.amount}
                              name="amount"
                              type="text"
                              placeholder="Please Enter Amount"
                            />
                            <span>{errorObj?.amount}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Type: </Form.Label>
                            <div>
                              <Form.Check
                                inline
                                type="radio"
                                label="Fixed"
                                value="f"
                                checked={input?.type === 'f'}
                                onChange={handleChange}
                                name="type"
                              />
                              <Form.Check
                                inline
                                type="radio"
                                label="Percentage"
                                value="p"
                                checked={input?.type === 'p'}
                                onChange={handleChange}
                                name="type"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        {/* <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Type : </Form.Label>
                            <div className="react-switch-slab" title={input?.type ? SlabSettingType.p : SlabSettingType.f}>
                              <ReactSwitch
                                onChange={handleSwitch}
                                checked={input?.type}
                                className="react-switch"
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                id="type"
                              />
                            </div>
                            <span>{errorObj?.type}</span>
                          </Form.Group>
                        </Col> */}
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/SlabSetting"
                          className="btn btn-danger btn-round mr-2"
                        >
                          Cancel
                        </Link>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-round btn-width"
                          disabled={isLoading}
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditSlabSetting;
