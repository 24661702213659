import React, { useEffect } from "react";

const Logout = () => {
  sessionStorage.clear();

  useEffect(() => {
    window.location.href = "/";
  }, []);
  return <></>;
};

export default Logout;
