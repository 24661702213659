import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import RdPlanServices from "./RdPlanServices";

const RdPlanList = ({ setPageLoading }) => {
  const { data, columns, rdPlanList } = RdPlanServices(setPageLoading);

  useEffect(() => {
    rdPlanList();
  }, []);

  return (
    <div>
      <div>
        <section className="content">
          <div className="container">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="block-header">
                      <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12">
                          <h2>RD Plan</h2>
                          <ul className="breadcrumb p-l-0 p-b-0 ">
                            <li className="breadcrumb-item">
                              <Link to="/">
                                <i className="fa fa-home"></i>
                              </Link>
                            </li>
                            <li className="breadcrumb-item active">
                              RD Plan List
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                          <Link to="/RDPlanAdd">
                            <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                              ADD
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row clearfix cus-height">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body cus-table mt-2">
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      keyField="objectId"
                      responsive
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[10, 20]}
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 215px)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RdPlanList;
