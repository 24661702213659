import React from "react";
import ListService from "./ListService";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";

const InvestorList = ({ setPageLoading }) => {
  const {
    userList,
    show,
    handleAmountChange,
    handleAmountsChange,
    userColumns,
    transferFunds,
    handleClose,
    data,
  } = ListService(setPageLoading);
  return (
    <section className="content">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <h2>Investor List</h2>
                      <ul className="breadcrumb p-l-0 p-b-0 ">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="fa fa-home"></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">User Management</li>
                        <li className="breadcrumb-item active">
                          Investor List
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                      <div className="btn-group float-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="dropdown"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle px-3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div
                          className="dropdown-menu cus-toggle"
                          style={{ top: "5px!important" }}
                        >
                          <Link className="dropdown-item" to="/FranchiseSignUp">
                            Franchises
                          </Link>
                          <Link className="dropdown-item" to="/InvestorSignUp">
                            Investors
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix cus-height">
          <div className="col-lg-12">
            <div className="card">
              {/* <div className="header">
                <div className="row">
                  <div className="col-md-9">
                    <h2>
                      <strong>Investor List</strong>
                    </h2>
                  </div>
                </div>
              </div> */}
              <div className="body cus-table mt-2">
                <DataTable
                  // title="Site List"
                  columns={userColumns}
                  data={userList}
                  pagination
                  keyField="objectId"
                  className="custom-table-overflow"
                  responsive
                  // expandableRows
                  // expandableRowsComponent={ExpandableComponent}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20]}
                  highlightOnHover
                  // fixedHeader
                  // fixedHeaderScrollHeight="calc(100vh - 215px)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="cus-modal-center" centered>
        <div className="bg-primary text-white d-flex">
          <Modal.Title className="text-white my-2 mx-4">
            Transfer Funds
          </Modal.Title>
          <span aria-hidden="true" onClick={handleClose} className="modal-cross pt-2">
            &times;
          </span>
        </div>
        <Modal.Body className="pb-1">
          <div className="form-group">
            <input
              name="amount"
              className="form-control"
              onChange={handleAmountsChange}
              placeholder="Please Enter Your Amount"
              value={data?.amount}
            ></input>
            <input
              name="remark"
              className="form-control mt-3"
              onChange={handleAmountChange}
              placeholder="Please Enter Your Remark"
              value={data?.remark}
            ></input>
          </div>
        </Modal.Body>
        <div className="container pb-2">
          <Button className="btn-primary" variant="secondary" onClick={transferFunds}>
            Transfer
          </Button>
        </div>
      </Modal>
    </section>
  );
};

export default InvestorList;
