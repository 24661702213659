
const Footer = () => {
    return (
        <div className="" >
            <div className="row clearfix m-0">
                <div className="col-md-12 col-lg-12 p-0">
                    <div className="card active-bg mb-0">
                        <div className="body p-1">
                            <p className="copyright m-0 text-white text-center">Copyright 2023 © All Rights Reserved. EazyDeel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer