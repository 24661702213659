import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataConstant } from "../../../../Services/DataConstant";
import { postRequest } from "../../../../Services/apiServices";
import { toast } from "react-toastify";

const ListService = (setPageLoading) => {
  const [userList, setUserList] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const handleClose = () => setShow(false);
  const [data, setData] = useState({});
  //   const navigate = useNavigate();

  async function getFranchizeList() {
    try {
      setPageLoading(true);
      var postResponse = await postRequest(DataConstant.FRANCHISELIST);
      if(postResponse.statusCode === 1) {
        setUserList(postResponse?.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }

  //   const handleUserChange = (e) => {
  //     navigate(e.target.value);
  //     console.log(e.target.value);
  //   };

  const userColumns = [
    {
      name: "#",
      width: "40px",
      cell: (row, index) => {
        return (
          <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
          <div className="custom-new-toggle-div">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
            ></button>
            <div className="dropdown-menu cus-toggle custom-new-toggle">
              <div className="cus-drop-down-list px-3">
                <Link className="py-2" to="/FranchiseSignUp" state={{ franchiseData: row }}>
                  Edit
                </Link>
              </div>
              <div className="cus-drop-down-list px-3">
                <Link to="/Ledger" className="py-2" state={{ mobileNo: row?.mobileNumber }}>
                  Ledger
                </Link>
              </div>
              <div
                className="cus-drop-down-list px-3 py-2"
                onClick={() => {
                  setShow(true);
                  setId(row.userID);
                }}
              >
                <span className="">Collect funds</span>
              </div>
            </div>
          </div>
          </>
        );
      },
    },
    {
      name: "Franchise Name",
      sortable: true,
      cell: (row) => {
        return row?.franchiseName;
      },
    },
    {
      name: "Mobile Number",
      sortable: true,
      cell: (row) => {
        return row?.mobileNumber;
      },
    },
    {
      name: <div className="cus-inr-text-header">Balance</div>,
      // sortable: true,
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.amount}
          </div>
        )
      },
    },
    {
      name: "GST Number",
      cell: (row) => {
        return row?.gst;
      },
    },
    {
      name: "Pan Number",
      cell: (row) => {
        return row?.pan;
      },
    },
    {
      name: "Email ID",
      sortable: true,
      cell: (row) => {
        return row?.emailId;
      },
    },
    {
      name: "Address",
      sortable: true,
      cell: (row) => {
        return row?.address;
      },
    },
    {
      name: "Postal code",
      cell: (row) => {
        return row?.pinCode;
      },
    },
  ];

  const handleAmountChange = (e) => {
    const { name } = e.target;
    let value = name === 'amount' ? e.target.value.replace(/\D/, ""): e.target.value;
    setData({ ...data, [name]: value });
  };

  const transferFunds = async () => {
    if (!data?.amount || !data?.remark) {
      return toast.error("Invalid Input", { toastId: "invalid-amount" });
    }
    try {
      setPageLoading(true);
      var postResponse = await postRequest("Fund/Transfer", {
        ...data,
        userId: id,
        trType: "d",
      });
      if (postResponse?.statusCode === 1) {
        toast.success("Success");
        getFranchizeList();
        handleClose();
        setData({});
      } else {
        toast.error(postResponse.responseText || "Failed to transfer");
      }
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
      toast.error("Something went wrong.");
    }
  }

  useEffect(() => {
    getFranchizeList();
  }, []);
  return {
    userColumns,
    userList,
    getFranchizeList,
    show,
    handleClose,
    handleAmountChange,
    data,
    transferFunds
  };
};

export default ListService;
