import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Capitalize } from "../../../../Services/Common";
import { postRequest } from "../../../../Services/apiServices";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../Services/Validation";

const InvestorServices = () => {
  const { state } = useLocation();
  const { InvestorData } = state || {};
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const initialState = {
    name: "",
    mobileNumber: "",
    emailId: "",
    pan: "",
    adhar: "",
    address: "",
    postalCode: "",
    permanentAddress: "",
  };

  const [inputValues, setInputValues] = useState(initialState);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });
    if (name === "emailId" && value) {
      if (!isValidEmail(value)) {
        setErrorObj((old) => {
          return {
            ...old,
            [name]: "Please enter a valid email.",
          };
        });
      }
    }

    setInputValues((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      let hasError = false;
      let obj = { ...inputValues };
      // Check all form fields for errors
      Object.keys(inputValues).forEach((name) => {
        const value = inputValues[name];
        if (!value) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });

      // If there are errors, prevent form submission
      if (hasError || errorObj?.emailId) {
        return;
      }

      if (obj?.password !== obj?.confirmPassword) {
        setErrorObj({ confirmPassword: "Passwords do not match" });
        return;
      }
      let url = "User/RegisterInvestor";
      if (InvestorData) {
        url = "User/UpdateInvestorDetails";
      }
      setIsLoading(true);
      const res = await postRequest(url, obj);

      if (res?.statusCode === 1) {
        toast.success(res?.responseText || "Successfully registered.");
        navigate("/InvestorList");
      } else {
        toast.error(res?.responseText || "Investor Registration failed.", {
          toastId: "Failed to Register",
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("eroor==>", "Failed to Register");
      toast.error("Investor Registration failed.", {
        toastId: "Failed to Register",
      });
    }
  };

  useEffect(() => {
    if (InvestorData) {
      setInputValues({
        name: InvestorData.name,
        mobileNumber: InvestorData.mobileNumber,
        emailId: InvestorData.emailId,
        pan: InvestorData.pan,
        adhar: InvestorData.adhar,
        address: InvestorData.address,
        postalCode: InvestorData.postalCode,
        permanentAddress: InvestorData.permanentAddress,
        userID: InvestorData.userID
      });
    } else {
      setInputValues(initialState);
    }
  }, [InvestorData]);
  return {
    handleChange,
    handleSignUp,
    InvestorData,
    inputValues,
    errorObj,
    isLoading
  };
};

export default InvestorServices;
