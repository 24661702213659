import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { LoanStatus, SearchType } from "../../../Services/interface";
import { Button } from "react-bootstrap";
import { postRequest } from "../../../Services/apiServices";

const LoanListService = (setPageLoading) => {
  const [applicationList, setApplicationList] = useState([]);
  const [EMIList, setEMIList] = useState([]);
  const [show, setShow] = useState(false);
  const [showForEMI, setShowForEMI] = useState(false);
  const [id, setId] = useState();
  const [input, setInput] = useState();
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setfromDate] = useState(new Date(Date.now() - 86400000));
  const [status, setStatus] = useState();
  const [remark, setRemark] = useState("");
  const [apiStatus, setApiStatus] = useState(-1);
  const [searchType, setSearchType] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShowForEMI(false);

  const applicationColumns = [
    {
      name: "#",
      width: "40px",
      cell: (row, index) => {
        return (
          <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="custom-new-toggle-div">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
            ></button>
            <div className="dropdown-menu cus-toggle custom-new-toggle">
              <div className="cus-drop-down-list px-3">
                <Link to="/ChangeLoanStatus" className="py-2" state={{ statusType: 'View', id: row?.id, redirectUrl: '/LoanApplicationList' }}>
                  View
                </Link>
              </div>
              {row?.status === 0 ? (
                <>
                  <div className="cus-drop-down-list px-3">
                    <Link to="/ChangeLoanStatus" className="py-2" state={{ statusType: 'Approve', id: row?.id, redirectUrl: '/LoanApplicationList' }}>
                      Approve
                    </Link>
                  </div>
                  <div className="cus-drop-down-list px-3">
                    <Link to="/ChangeLoanStatus" className="py-2" state={{ statusType: 'Reject', id: row?.id, redirectUrl: '/LoanApplicationList' }}>
                      Reject
                    </Link>
                  </div>
                </>
              ): (
                <div className="px-3 py-2">
                  <span className={row?.status === 1 ? "text-success": "text-danger"}>
                    {LoanStatus[row?.status]}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      name: "Customer",
      width: "125px",
      cell: (row) => {
        return row?.customerName ? (
          <span>{row.customerName} <br /> 
            [<span className="user-info-ledger">{row.customerMobile}/{row.customerId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "Investor",
      width: "125px",
      cell: (row) => {
        return row?.investorName ? (
          <span>{row.investorName} <br /> 
            [<span className="user-info-ledger">{row.investorMobile}/{row.investorId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "franchise",
      width: "125px",
      cell: (row) => {
        return row?.franchiseName ? (
          <span>{row.franchiseName} <br /> 
            [<span className="user-info-ledger">{row.franchiseMobile}/{row.franchiseId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "Product Name",
      width: "125px",
      sortable: true,
      cell: (row) => {
        return row?.productName;
      },
    },
    // {
    //   name: "Brand",
    //   sortable: true,
    //   cell: (row) => {
    //     return row?.brand;
    //   },
    // },
    {
      name: <div className="cus-inr-text-header">Loan Amount</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success"></i>{" "}
            {row?.loanAmount}
          </div>
        );
      },
    },
    {
      name: "Loan Tenure",
      width: "125px",
      cell: (row) => {
        return row?.loanTenure;
      },
    },
    {
      name: "InvoiceNo",
      cell: (row) => {
        return row?.invoiceNo;
      },
    },
    {
      name: <div className="cus-inr-text-header">MRP</div>,
      width: "130px",
      cell: (row) => {
        // return row?.mrp;
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.mrp}
          </div>
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Selling Price</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.sellingPrice}
          </div>
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Processing Fee</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.processingFee}
          </div>
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Membership Fee</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.membershipFee}
          </div>
        );
      },
    },
    {
      name: "BatchNo",
      cell: (row) => {
        return row?.batchNo;
      },
    },
    {
      name: "Remark",
      width: "170px",
      cell: (row) => {
        return row?.remark;
      },
    },
    {
      name: "Apply On",
      cell: (row) => {
        return row?.entryOn;
      },
    },
    {
      name: "EMI",
      width: "130px",
      cell: (row) => {
        return (
          <>
            {
              row?.status === 1 ? (
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setShowForEMI(true);
                    getEMIList(row?.id);
                  }}
                >
                  Read More
                </Button>
              ): ""
            }
          </>
        );
      },
    },
  ];

  const tableColumns = [
    {
      name: "#",
      width: "40px",
      cell: (row, index) => {
        return (
          <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
        );
      },
    },
    {
      name: "Schedule",
      sortable: true,
      cell: (row) => {
        return row?.scheduleOn;
      },
    },
    {
      name: "Paid On",
      width: "125px",
      sortable: true,
      cell: (row) => {
        return (
          row?.paidOn ? row?.paidOn : (
            new Date(row?.scheduleOn) > new Date() ? (
              <span>N/A</span>
            ): <span>Bounce</span>
          )
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Amount</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.amount}
          </div>
        );
      },
    },
  ];

  const getApplicationList = async () => {
    try {
      setPageLoading(true);
      const resData = await postRequest("LoanApplication/GetLoanApplications", {
        status: apiStatus,
        textSearch: searchKeyword,
        searchType: searchType,
        toDate: moment(toDate).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
        loginId: 0,
      });
      if (resData) {
        setApplicationList(resData);
      }
      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      console.log("error=>", err);
    }
  };

  const getEMIList = async (id) => {
    try {
      const resData = await postRequest(
        "LoanApplication/GetEMIRes",
        false,
        false,
        {
          LoanId: id,
        }
      );
      if (resData?.result) {
        setEMIList(resData.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprovedChange = async () => {
    try {
      if (!remark) {
        return toast.error("Please enter remark.", {toastId: "invalid-remark"});
      }
      let obj = {
        loanId: id,
        status,
        remark: remark || "",
        entryBy: 0,
      };

      const resData = await postRequest(
        "LoanApplication/ChangeLoanApproveStatus",
        obj
      );
      if (resData?.statusCode == 1) {
        setShow(false);

        getApplicationList();
        toast.success(resData?.responseText || "Status updated.");
      } else {
        toast.error(resData?.responseText || "Failed to update status.", {
          toastId: "Failed-application",
        });
      }
    } catch (err) {
      console.log("error=>", err);
      toast.error("Something went wrong.");
    }
  };

  const handleFromDateChange = async (e) => {
    setfromDate(e);
  };

  const handleToDateChange = async (e) => {
    setToDate(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInput((prev) => {
      return {
        ...prev,
        [name]: name === "orderStatus" ? parseInt(value) : value,
      };
    });
  };

  useEffect(() => {
    getApplicationList();
  }, []);
  return {
    applicationColumns,
    tableColumns,
    getApplicationList,
    handleApprovedChange,
    handleFromDateChange,
    handleToDateChange,
    handleClose,
    applicationList,
    show,
    fromDate,
    toDate,
    setApiStatus,
    showForEMI,
    handleClose2,
    EMIList,
    searchType,
    setSearchType,
    setSearchKeyword,
    setRemark,
  };
};

export default LoanListService;
