import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InvestorServices from "./InvestorServices";

const InvestorSignup = () => {
  const { handleChange, handleSignUp, InvestorData, inputValues, errorObj, isLoading } =
    InvestorServices();
  return (
    <section className="content">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <h2>
                        {InvestorData ? "Update Investor" : "Register Investor"}
                      </h2>
                      <ul className="breadcrumb p-l-0 p-b-0 ">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="fa fa-home"></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">Investor Management</li>
                        {InvestorData ? (
                          <li className="breadcrumb-item active">
                            Update Investor
                          </li>
                        ) : (
                          <li className="breadcrumb-item active">
                            Register Investor
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="authentication no-authentication cus-height">
          <div className="col-md-12 content-center content-center-sign-up">
            <div className="row clearfix mb-4">
              <div className="col-lg-12 m-auto">
                <div className="card-plain card-plain-sign-up">
                  <Form onSubmit={handleSignUp} className="form-div">
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="name"
                            type="text"
                            value={inputValues?.name}
                            placeholder="Name"
                          />
                          <span>{errorObj?.name}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="emailId"
                            type="text"
                            value={inputValues?.emailId}
                            placeholder="Email"
                          />
                          <span>{errorObj?.emailId}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="mobileNumber"
                            type="text"
                            value={inputValues?.mobileNumber}
                            placeholder="Mobile Number"
                          />
                          <span>{errorObj?.mobileNumber}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="address"
                            type="text"
                            value={inputValues?.address}
                            placeholder="Address"
                          />
                          <span>{errorObj?.address}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="permanentAddress"
                            type="text"
                            value={inputValues?.permanentAddress}
                            placeholder="Permanent Address"
                          />
                          <span>{errorObj?.permanentAddress}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="postalCode"
                            type="text"
                            value={inputValues?.postalCode}
                            placeholder="Postal Code"
                          />
                          <span>{errorObj?.postalCode}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="adhar"
                            type="text"
                            value={inputValues?.adhar}
                            placeholder="Aadhar"
                          />
                          <span>{errorObj?.adhar}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="pan"
                            type="text"
                            value={inputValues?.pan}
                            placeholder="Pan"
                          />
                          <span>{errorObj?.pan}</span>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <Link
                        to="/InvestorList"
                        className="btn btn-danger btn-round mr-2"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary btn-round btn-width"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestorSignup;
