import React from "react";
import { useState } from "react";
import { postRequest } from "../../Services/apiServices";
import { toast } from "react-toastify";
import moment from "moment";

const LedgerReport = () => {
  const [ledgerList, setLedgerList] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setfromDate] = useState(new Date(Date.now() - 86400000));

  const handleFromDateChange = (e) => {
    setfromDate(e);
  };
  const handleToDateChange = (e) => {
    setToDate(e);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "80px",
    },

    {
      name: "NAME",
      selector: (row) => row.name,
      width: "100px",
    },

    {
      name: "ROLE",
      selector: (row) => row.role,
      width: "100px",
    },
    {
      name: "TRANSACTION TYPE",
      selector: (row) => row.trType,
      width: "150px",
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      width: "100px",
    },
    {
      name: "OPENING",
      selector: (row) => row.opening,
      width: "100px",
    },
    {
      name: "CLOSING",
      selector: (row) => row.clossing,
      width: "100px",
    },
    {
      name: "PROCESSING FEE",
      selector: (row) => row.processingFee,
      width: "150px",
    },
    {
      name: "MEMBERSHIP FEE",
      selector: (row) => row.membershipFee,
      width: "150px",
    },
    {
      name: "DESCRIPTION ",
      selector: (row) => row.description,
      width: "250px",
    },
    {
      name: "REMARK",
      selector: (row) => row.remark,
      width: "250px",
    },
    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "150px",
    },
  ];

  const data = ledgerList?.map((d, i) => ({
    serial: i + 1,
    name: d?.name,
    role: d?.role,
    trType: d?.trType,
    opening: (
      <>
        <i className="text-success fa fa-inr" aria-hidden="true"></i>
        &nbsp;{d?.opening}
      </>
    ),
    clossing: (
      <>
        <i className="text-success fa fa-inr" aria-hidden="true"></i>
        &nbsp;{d?.clossing}
      </>
    ),
    amount: (
      <>
        <i className="text-success fa fa-inr" aria-hidden="true"></i>
        &nbsp;{d?.amount}
      </>
    ),
    processingFee: (
      <>
        <i className="text-success fa fa-inr" aria-hidden="true"></i>
        &nbsp;{d?.processingFee}
      </>
    ),
    membershipFee: (
      <>
        <i className="text-success fa fa-inr" aria-hidden="true"></i>
        &nbsp;{d?.membershipFee}
      </>
    ),
    description: d?.description,
    remark: d?.remark,
    entryOn: d?.entryOn,
  }));

  const getLedgerList = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(`Report/GetLedger`, {
        toDate: moment(toDate).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
        userId: 0,
      });
      if (!res.result) {
        toast.error(res.result.error);
      } else {
        setLedgerList(res.result);
      }
    } catch (error) {
      toast.error("Something went wrong!!!");
    }
  };

  return {
    data,
    columns,
    getLedgerList,
    fromDate,
    toDate,
    handleFromDateChange,
    handleToDateChange,
  };
};

export default LedgerReport;
