import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { postRequest } from "../../Services/apiServices"

const Dashboard = ({setPageLoading}) => {

    const [dashboardData, setDashboardData] = useState({});

    const getInitialData = async () => {
        try {
            setPageLoading(true);
            const resData = await postRequest(
                "Dashboard/AdminDashboardSummary"
            );
            if (resData?.statusCode === 1) {
                setDashboardData(resData.result);
            }
            setPageLoading(false);
        } catch (error) {
            setPageLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        getInitialData();
    }, [])

    return (
        <div>
            <div className="overlay"></div>

            <section className="content">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="body">
                                    <div className="block-header">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-8 col-sm-12">
                                                <h2>Dashboard</h2>
                                                <ul className="breadcrumb p-l-0 p-b-0 ">
                                                    <li className="breadcrumb-item"><Link to="/"><i className="fa fa-home"></i></Link></li>
                                                    <li className="breadcrumb-item active">Dashboard</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cus-height">
                        <div className="row clearfix ">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 total-loan-amt common-card">
                                    <div className="card-body-block">
                                        <div >TOTAL LOAN</div>
                                        <div className="pt-1">
                                            <img src="/assets/images/dashboard/total plan.svg"></img>
                                            {/* <i className="fa fa-sticky-note-o fa-2x m-3"></i> */}
                                            <span className="card-amt-text mx-3"> 
                                                {dashboardData?.totalLoanAmt ?
                                                (<span>{dashboardData.totalLoanCount} | {dashboardData.totalLoanAmt}</span>) : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2" style={{backgroundColor: "#188771"}}>
                                    <div className="card-body-block">
                                        <div >TOTAL LOAN</div>
                                        <div>
                                        <span style={{borderRadius: "50%"}}><i className="fa fa-sticky-note-o fa-2x m-3"></i></span>
                                            <span className="card-amt-text"> 
                                            {dashboardData?.totalLoanAmt ?
                                        (<span>{dashboardData.totalLoanCount} | {dashboardData.totalLoanAmt}</span>) : 0}
                                        </span></div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 today-emi-amt common-card">
                                    <div className="card-body-block">
                                        <div >TODAY EMI</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/todayemi.svg"></img>
                                            {/* <i className="fa fa-calculator fa-2x m-3"></i> */}
                                            <span className="card-amt-text mx-3">
                                                {dashboardData?.todayEMIAmt ?
                                            (<span>{dashboardData.todayEMICount} | {dashboardData.todayEMIAmt}</span>) : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 collection-in-month-amt common-card">
                                    <div className="card-body-block">
                                        <div >COLLECTION (In month)</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/collection.svg"></img>
                                            {/* <i className="fa fa-calendar-o fa-2x m-3"></i> */}
                                            <span className="card-amt-text mx-3">
                                                {dashboardData?.collectionInMonthAmt ?
                                                (<span>{dashboardData.collectionInMonthCount} | {dashboardData.collectionInMonthAmt}</span>) : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 upcoming-emi-amt common-card">
                                    <div className="card-body-block">
                                        <div>UPCOMING EMI</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/upcoming.svg"></img>
                                            {/* <i className="fa fa-clock-o fa-2x m-3"></i> */}
                                            <span  className="card-amt-text mx-3">
                                                {dashboardData?.upComingEMIAmt ?
                                            (<span>{dashboardData.upComingEMICount} | {dashboardData.upComingEMIAmt}</span>) : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 total-approved-amt common-card">
                                    <div className="card-body-block">
                                        <div>TOTAL APPROVED LOAN</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/total approved loan.svg"></img>
                                        {/* <i className="fa fa-rocket fa-2x m-3"></i> */}
                                        <span className="card-amt-text mx-3"> 
                                        {dashboardData?.totalApproveLoanAmt ?
                                        (<span>{dashboardData.totalApproveLoanCount} | {dashboardData.totalApproveLoanAmt}</span>) : 0}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 total-pending-loan common-card">
                                    <div className="card-body-block">
                                        <div>TOTAL PENDING LOAN</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/total pending loan.svg"></img>
                                            {/* <i className="fa fa-hourglass fa-2x m-3"></i> */}
                                            <span className="card-amt-text mx-3">
                                            {dashboardData?.totalPendingLoanAmt ?
                                            (<span>{dashboardData.totalPendingLoanCount} | {dashboardData.totalPendingLoanAmt}</span>) : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 total-rejected-loan common-card">
                                    <div className="card-body-block">
                                        <div>TOTAL REJECTED LOAN</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/total rejected loan.svg"></img>
                                            {/* <i className="fa fa-times fa-2x m-3"></i> */}
                                            <span className="card-amt-text mx-3">
                                            {dashboardData?.totalRejectLoanAmt ?
                                            (<span>{dashboardData.totalRejectLoanCount} | {dashboardData.totalRejectLoanAmt}</span>) : 0}
                                        </span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card info-box-2 total-rejected-loan common-card">
                                    <div className="card-body-block">
                                        <div>TOTAL RD</div>
                                        <div className="pt-1">
                                        <img src="/assets/images/dashboard/rd1.svg"></img>
                                            {/* <i className="fa fa-times fa-2x m-3"></i> */}
                                            <span className="card-amt-text mx-3">
                                            {dashboardData?.totalRDAmt ?
                                            (<span>{dashboardData.totalRDCount} | {dashboardData.totalRDAmt}</span>) : 0}
                                        </span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Dashboard