import React, { useEffect, useState } from "react";
import { postRequest } from "../../Services/apiServices";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Capitalize } from "../../Services/Common";

const SlabSettingService = ({ setPageLoading }) => {
  const initialState = {
    id: 0,
    start: "",
    end: "",
    amount: "",
    level: "",
    slabId: "",
    fromMonth: "",
    toMonth: "",
    type: "f" // fixed, percentage
  }
  const [slabData, setSlabData] = useState([]);
  const [masterSlab, setMasterSlab] = useState([]);
  const [input, setInput] = useState(initialState);
  const [errorObj, setErrorObj] = useState(initialState);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {state} = useLocation();
  const columns = [
    {
      name: "#",
      cell: (row) => row.serial,
      width: "80px",
    },
    {
      name: "ACTIONS",
      cell: (row) => row.actions,
      width: "120px",
    },
    {
      name: "LEVEL",
      cell: (row) => row.level,
    },
    {
      name: "SLAB",
      cell: (row) => row.slabId,
    },
    {
      name: "START",
      cell: (row) => row.start,
    },
    {
      name: "END",
      cell: (row) => row.end,
    },
    {
      name: "FROM MONTH",
      cell: (row) => row.fromMonth,
      width: "120px",
    },
    {
      name: "TO MONTH",
      cell: (row) => row.toMonth,
    },
    {
      name: <div className="cus-inr-text-header">AMOUNT</div>,
      cell: (row) => row.amount,
    },
    // {
    //   name: "TYPE",
    //   cell: (row) => row.type,
    // },
    {
      name: "ENTRY ON ",
      cell: (row) => row.entryOn,
    },
    {
      name: "MODIFY ON ",
      cell: (row) => row.modifyOn,
    },
  ];

  const data = slabData?.map((d, i) => ({
    serial: i + 1,
    actions: (
      <>
        <div className="custom-new-toggle-div">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
          ></button>
          <div className="dropdown-menu cus-toggle custom-new-toggle">
            <div className="cus-drop-down-list px-3 py-1">
              <Link
                to={{
                  pathname: "/AddSlabSetting",
                }}
                state= {{editData: d}}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </>
    ),
    level: d?.level,
    slabId: (
      <>
        {masterSlab?.find(slab => slab.id === d?.slabId)?.name}
      </>
    ),
    start: d?.start,
    end: d?.end,
    amount: (
      <>
        <div className="cus-inr-text-value">
          {d.type === 'f' ? <i className="fa fa-inr text-success"></i>: ""} 
          {" "}{d?.amount}
          {" "}{d.type === 'p' ? <i className="fa fa-percent text-primary"></i>: ""} 
        </div>
      </>
    ),
    // type: d.type?.toLowerCase(),
    fromMonth: d?.fromMonth,
    toMonth: d?.toMonth,
    entryOn: d?.entryOn,
    modifyOn: d?.modifyOn,
  }));

  const getSlabSettingList = async () => {
    try {
      setPageLoading(true);
      const res = await postRequest("CommissionSetting/Get");
      if (res?.result) {
        setSlabData(res.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  };

  const slabSettingById = async () => {
    try {
      const Id = state?.editData?.id;
      if (!Id) {
        return
      }
      setPageLoading(true);
      const res = await postRequest(
        `CommissionSetting/GetById`,
        null,
        "application/json",
        {id: Id}
      );
      if (res?.statusCode === 1) {
        setInput({
          start: res.result.start,
          end: res.result.end,
          amount: res.result.amount,
          type: res.result.type,
          slabId: res.result.slabId,
          level: res.result.level,
          fromMonth: res.result.fromMonth,
          toMonth: res.result.toMonth,
          id: state?.editData?.id || 0,
        });
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    // if (name === 'amount') {
      // value = value.replace(/\D/, "");
    // }
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let hasError = false;
      // Check all form fields for errors
      Object.keys(input).forEach((name) => {
        const value = input[name];
        if (name !== "type" && (value === "" || value === null)) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
        if (name !== "type" && value) {
          if (isNaN(value)) {
            setErrorObj((old) => ({
              ...old,
              [name]: "Please enter a valid numeric value.",
            }));
            hasError = true;
          }
        }
      });
      if (hasError) {
        return;
      }
      setIsLoading(true);
      const res = await postRequest(
        `CommissionSetting/Add`,
        {
          start: +input.start,
          end: +input.end,
          amount: +input.amount,
          type: !input.type ? "f": "p",
          level: +input.level,
          slabId: +input.slabId,
          id: state?.editData?.id || 0,
          fromMonth: +input.fromMonth,
          toMonth: +input.toMonth
        },
      );
      if (res?.statusCode === 1) {
        toast.success(res.responseText || "Success.");
        navigate("/SlabSetting");
      } else {
        toast.error(res?.responseText || "Failed to add Slab Setting.");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  
  const getMasterSlabList = async () => {
    try {
      setPageLoading(true);
      const res = await postRequest("CommissionSetting/GetMasterSlab");
      if (res?.statusCode === 1) {
        setMasterSlab(res.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getMasterSlabList();
  }, []);

  return {
    data,
    columns,
    getSlabSettingList,
    input,
    handleChange,
    handleSubmit,
    isLoading,
    errorObj,
    slabSettingById,
    getMasterSlabList,
    masterSlab,
  };
};

export default SlabSettingService;
