import React, { useState } from "react";
import { getRequest, postRequest } from "../../Services/apiServices";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Capitalize } from "../../Services/Common";
import { Modal } from "react-bootstrap";

const CategoriesServices = (setPageLoading) => {
  const initialState = {
    isPublish: true,
    banners: "",
    iconImg: "",
    taxRate: "",
    categoryName: "",
    parentId: 0,
  }
  const [getCategoryList, setGetCategoryList] = useState([]);
  const [input, setInput] = useState(initialState);
  const [errorObj, setErrorObj] = useState({});
  const [image, setImage] = useState();
  const [banner, setBanner] = useState();
  const navigate = useNavigate();
  const param = useParams();
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  const handlePublish = async (e, categoryId, isPublish) => {
    try {
      setPageLoading(true);
      let obj = {
        "categoryId": categoryId,
        "isPublish": isPublish ? false : true
      }
      const res = await postRequest(`Category/ChangeStatus`, obj);
      await categoryList();
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText || 'Failed to change category status');
      }
      setPageLoading(false);
    } catch (error) {
      toast.error('Failed to change category status');
      setPageLoading(false);
    }
  }

  //////****** GET CATEGORY LIST *///////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "80px",
    },

    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "120px",
    },

    {
      name: "NAME",
      selector: (row) => row.categoryName,
      width: "150px",
    },

    {
      name: "ICON",
      selector: (row) => row.icon,
      width: "150px",
    },
    {
      name: "BANNER",
      selector: (row) => row.banners,
      width: "150px",
    },
    {
      name: "TAX RATE",
      selector: (row) => row.taxRate,
      width: "200px",
    },
    {
      name: "ISPUBLISH",
      selector: (row) => row.isPublish,
      width: "150px",
    },

    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "220px",
    },
  ];

  const data = getCategoryList?.map((d, i) => ({
    serial: i + 1,
    categoryName: d?.categoryName,
    action: (
      <>
        {/* <Link
          to={{
            pathname: "/EditCategories/" + d?.categoryId,
          }}
        >
          <i title="Edit" className="fa fa-edit fa-2x text-info"></i>
        </Link>
        <span
          onClick={() => handlepopup(d?.categoryId)}
          style={{ cursor: "pointer" }}
        >
          <i title="Delete" className="fa fa-trash fa-2x text-danger mx-3"></i>
        </span> */}
        <div className="custom-new-toggle-div">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
          ></button>
          <div className="dropdown-menu cus-toggle custom-new-toggle">
            <div className="cus-drop-down-list px-3">
              <Link className="py-2" 
                to={{
                  pathname: "/EditCategories/" + d?.categoryId,
                }}
              >
                Edit
              </Link>
            </div>
            {/* <div className="cus-drop-down-list px-3">
              <div className="py-2" onClick={() => handlepopup(d?.categoryId)}>
                Delete
              </div>
            </div> */}
            <div className="cus-drop-down-list px-3">
              <div className="py-2" onClick={(e) => handlePublish(e, d?.categoryId, d?.isPublish)}>
                {d?.isPublish ? "UnPublish": "Publish"}
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    icon: (
      <div className="cat-icon">
        <div className="thumbnail-container py-1">
          <img src={d?.icon} alt="Image" width={50} height={50} style={{ cursor: 'pointer' }}
            onClick={() => handleImageClick({src: d?.icon, alt: "image"})}
            onError={(e) => (
              (e.target.src = "/assets/images/noimage.jpg"),
              (e.target.style.width = "40px"),
              (e.target.style.height = "40px")
            )} 
          />
        </div>
        <Modal show={modalOpen && selectedImage} className="img-larger-modal-cn" onHide={handleCloseModal}>
          <div className="">
            <span className="img-larger-close" onClick={handleCloseModal}>&times;</span>
            <img src={selectedImage?.src} alt={"Image"} 
              onError={(e) => (
                (e.target.src = "/assets/images/noimage.jpg"),
                (e.target.style.width = "300px"),
                (e.target.style.height = "300px")
              )}
            />
          </div>
        </Modal>
      </div>
    ),
    banners: (
      <div className="cat-icon">
        <div className="thumbnail-container py-1">
          <img src={d?.banners} alt="Image" width={50} height={50} style={{ cursor: 'pointer' }}
            onClick={() => handleImageClick({src: d?.banners, alt: "banners"})}
            onError={(e) => (
              (e.target.src = "/assets/images/noimage.jpg"),
              (e.target.style.width = "40px"),
              (e.target.style.height = "40px")
            )} 
          />
        </div>
      </div>
    ),
    taxRate: d?.taxRate,
    entryOn: d?.entryOn,
    isPublish: d?.isPublish ? (
      <img src="/assets/images/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="assets/images/delete.png" style={{ width: "20px" }} />
    ),
  }));

  const categoryList = async () => {
    try {
      setPageLoading(true);
      const res = await getRequest(`Category/GetAllAsync`);
      if (res?.statusCode === 1) {
        setGetCategoryList(res.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  };
  //////****** GET CATEGORY LIST *///////

  //////****** ADD AND EDIT CATEGORY LIST *///////

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: name === "isPublish" ? checked : value };
    });
    if(!["isPublish", "parentId"].includes(name)) {
      setErrorObj((old) => {
        return {
          ...old,
          [name]: value ? "" : Capitalize(name) + " is required",
        };
      });
    }
  };

  const handleIconImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["iconImg"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
    setErrorObj((old) => {
      return {
        ...old,
        ["iconImg"]: ""
      };
    });
  };

  const handleBanner = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["banners"] = objectUrl;
    setBanner({
      value: e.target.files[0],
    });
    setErrorObj((old) => {
      return {
        ...old,
        ["banners"]: ""
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let hasError = false;
      // Check all form fields for errors
      Object.keys(input).forEach((name) => {
        const value = input[name];
        if ((value === "" || value === null) && name !== "parentId") {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });
      if (hasError) {
        return;
      }
      setIsLoading(true);
      let res = {};
      let formData = new FormData();
      let __d = { ...input };
      __d.banners = banner?.value || "";
      __d.iconImg = image?.value || "";
      if (!param?.CategoryId) {
        __d.categoryId = 0;
      } else {
        __d.categoryId = param?.CategoryId;
      }
      for (let key in __d) {
        formData.append(key, __d[key]);
      }
      res = await postRequest(
        `Category/AddAsync`,
        formData,
        "multipart/form-data"
      );
      if (res?.statusCode !== 1) {
        toast.error(res?.responseText ||"Failed to add category.");
      } else {
        navigate("/CategoriesList");
        toast.success(res.responseText || "Success");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to add category.");
    }
  };
  //////****** ADD AND EDIT CATEGORY LIST *///////

  //////****** GET BY ID CATEGORY LIST *///////

  const getByIdCategoryList = async () => {
    try {
      const res = await postRequest(
        `Category/GetByIdAsync/${param?.CategoryId}`
      );
      if (res?.statusCode === 1) {
        setInput({
          isPublish: res.result.isPublish,
          banners: res.result.banners,
          iconImg: res.result.icon,
          taxRate: res.result.taxRate,
          categoryName: res.result.categoryName,
          parentId: res.result.parentId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  //////****** GET BY ID CATEGORY LIST *///////

  //////****** GET BY CATEGORYID TO DELETE CATEGORY LIST *///////
  const handlepopup = async (Id) => {
    setShowModal(true);
    setGetId(Id);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`Category/DeleteCategoryAsync/${getId}`);
      if (res?.statusCode === 1) {
        categoryList();
        setShowModal(false);
        toast.success(res?.responseText);
      } else {
        toast.error(res?.responseText);
      }
    } catch (error) {
      toast.error("Failed to delete category");
      console.log(error);
    }
  };

  //////****** GET BY CATEGORYID TO DELETE CATEGORY LIST *///////

  return {
    data,
    columns,
    categoryList,
    handleBanner,
    handleChange,
    handleIconImage,
    handleSubmit,
    input,
    getByIdCategoryList,
    handleClose,
    handleDelete,
    showModal,
    getCategoryList,
    isLoading,
    errorObj
  };
};

export default CategoriesServices;
