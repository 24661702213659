import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import LoanListService from "./PendingLoanService";
const PendingLoan = ({ setPageLoading }) => {
  const {
    applicationColumns,
    handleApprovedChange,
    handleClose,
    applicationList,
    show,
    setRemark,
  } = LoanListService(setPageLoading);
  return (
    <section className="content">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body ">
                <div className="block-header">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <h2>Pending Loan</h2>
                      <ul className="breadcrumb p-l-0 p-b-0 ">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="fa fa-home"></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Pending Loan
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix cus-height">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <DataTable
                  columns={applicationColumns}
                  data={applicationList}
                  pagination
                  keyField="objectId"
                  responsive
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20]}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 215px)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} size="sm" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mt-3" style={{ marginLeft: "42px" }}>
            Confirmation
          </Modal.Title>
          <span
            aria-hidden="true"
            onClick={handleClose}
            className="modal-cross"
          >
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className="pt-0">
          Aye you sure you want to proceed!
        </Modal.Body>
        <textarea
          name="comments"
          className="cus-comments"
          id="comments"
          onChange={(e) => setRemark(e.target.value)}
          placeholder="Please Enter Your Remark"
        ></textarea>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleApprovedChange}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default PendingLoan;
