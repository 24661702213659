import React, { useState, useEffect } from "react";
import moment from "moment";
import { postRequest } from "../../Services/apiServices";
import { TransactionType } from "../../Services/interface";
import { useLocation } from "react-router-dom";

const LedgerServices = (setPageLoading) => {
  const [applicationList, setApplicationList] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setfromDate] = useState(new Date(Date.now() - 86400000));
  const [searchKeyword, setSearchKeyword] = useState("");
  const { state } = useLocation();
  const { mobileNo } = state || {};

  const applicationColumns = [
    {
      name: "#",
      width: "40px",
      cell: (row, index) => {
        return (
          <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
        );
      },
    },
    {
      name: "User",
      width: "150px",
      // sortable: true,
      cell: (row) => {
        return row?.name ? (
          <span>{row.name} <br /> 
            [<span className="user-info-ledger">{row.phoneNumber}/{row.userId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "TID",
      cell: (row) => {
        return row?.tid;
      },
    },
    // {
    //   name: "Service Name",
    //   cell: (row) => {
    //     return row?.serviceName;
    //   },
    // },
    {
      name: "Entry On",
      cell: (row) => {
        return row?.entryOn;
      },
    },
    {
      name: "Description",
      width: "200px",
      cell: (row) => {
        return <span className="py-2">{row?.description}</span>
      },
    },
    {
      name: "Transaction Type",
      cell: (row) => {
        return (
          <span class={TransactionType[row?.trType] === 'Debited' ? 'badge badge-danger': 'badge badge-success'}>{TransactionType[row?.trType]}</span>
        )
      },
    },
    {
      name: <div className="cus-inr-text-header">Opening</div>,
      // width: "100px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success"></i>
            &nbsp;{row?.opening}
          </div>
        )
      },
    },
    {
      name: <div className="cus-inr-text-header">Amount</div>,
      // width: "100px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success"></i> {" "}
            {row?.amount}
          </div>
        );
      },
    },
    // {
    //   name: "Role",
    //   sortable: true,
    //   cell: (row) => {
    //     return row?.role;
    //   },
    // },
    {
      name: <div className="cus-inr-text-header">Closing</div>,
      // width: "120px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success"></i> {" "}
            {row?.clossing}
          </div>
        )
      },
    },    
    {
      name: "Remark",
      width: "180px",
      cell: (row) => {
        return <span className="py-2">{row?.remark}</span>;
      },
    },
  ];

  const handleFromDateChange = async (e) => {
    setfromDate(e);
  };

  const handleToDateChange = async (e) => {
    setToDate(e);
  };

  useEffect(() => {
    if (mobileNo) {
      setSearchKeyword(mobileNo);
    }
    getLedgerReport(mobileNo || '');

  }, [mobileNo]);

  const getLedgerReport = async (mobile) => {
    try {
      setPageLoading(true);
      const resData = await postRequest("Report/GetLedger", {
        todate: moment(toDate).format("DD MMM YYYY"),
        fromdate: moment(fromDate).format("DD MMM YYYY"),
        userId: 0,
        mobileNo: mobile || searchKeyword
      });
      if (resData?.statusCode === 1) {
        setApplicationList(resData.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log("error=>", error);
    }
  };
  return {
    toDate,
    fromDate,
    handleFromDateChange,
    handleToDateChange,
    applicationList,
    applicationColumns,
    getLedgerReport,
    setSearchKeyword,
    searchKeyword
  };
};

export default LedgerServices;
