import React from "react";
import { Link } from "react-router-dom";
import ChangeLoanStatusServices from "./ChangeStatusService";
import { Form } from "react-bootstrap";
const ChangeLoanStatus = ({ setPageLoading }) => {

    const { loanData, statusType, investorList, handleChange, inputValues, handleSubmit, handleCancel, handlePrintScreen } = ChangeLoanStatusServices(setPageLoading)

    return (
        <section className="content">
            <div className="container">
                <div className="row clearfix hide-on-print-screen">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="body ">
                                <div className="block-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-8 col-sm-12">
                                            <h2>Change Loan Status</h2>
                                            <ul className="breadcrumb p-l-0 p-b-0 ">
                                                <li className="breadcrumb-item">
                                                    <Link to="/">
                                                        <i className="fa fa-home"></i>
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    Loan Status
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-12">
                        {
                            loanData?.emailId ? (
                                <div className="card">
                                    <div className="d-md-flex">
                                        <div className="px-md-3 px-0 py-3 col-md-6">
                                            <strong className="header-text px-2">Customer Details</strong>
                                            <div className="container my-2">
                                                <div className="d-flex">
                                                    <div className="status-labels pr-md-5">
                                                        <p>Name:</p>
                                                        <p>Email Id:</p>
                                                        <p>Mobile:</p>
                                                        <p>Parmanent Address:</p>
                                                        <p>Postal Code:</p>
                                                        <p>Mailing Address:</p>
                                                        <p>Postal Code:</p>
                                                        <p>Alternate Mobile:</p>
                                                        <p>Pan:</p>
                                                        <p className="">Aadhar:</p>
                                                    </div>
                                                    <div className="values">
                                                        <p>
                                                            {loanData?.customerName}
                                                            <div className="profile-pic-user">
                                                                <img 
                                                                    width={120}
                                                                    height={120}
                                                                    className=""
                                                                    src={loanData?.panImgPath} alt="Profile" onError={(e) => (
                                                                        (e.target.src = "/assets/images/noimage.jpg"),
                                                                        (e.target.style.width = "80px"),
                                                                        (e.target.style.height = "80px")
                                                                    )} 
                                                                />
                                                            </div>
                                                        </p>
                                                        <p>{loanData?.emailId}</p>
                                                        <p>{loanData?.mobile}</p>
                                                        <p>{loanData?.parmanentAddress}</p>
                                                        <p>{loanData?.postalCode_P}</p>
                                                        <p>{loanData?.mailingAddress}</p>
                                                        <p>{loanData?.postalCode_M}</p>
                                                        <p>{loanData?.alternateMobile}</p>
                                                        <span className="d-flex">
                                                            {loanData?.pan}
                                                            <span className="thumbwrap-img mx-4">
                                                                <a className="thumb-img thumb-img-pan" href="#">
                                                                    <img width={60} height={60} className="thumb-base-img" src={loanData?.panImgPath} alt="Pan Image" onError={(e) => (
                                                                        (e.target.src = "/assets/images/noimage.jpg"),
                                                                        (e.target.style.width = "40px"),
                                                                        (e.target.style.height = "40px")
                                                                    )}  />
                                                                    <span>
                                                                        <img className="hovered-img" src={loanData?.panImgPath} alt=""
                                                                            onError={(e) => (
                                                                                (e.target.src = "/assets/images/noimage.jpg"),
                                                                                (e.target.style.width = "300px"),
                                                                                (e.target.style.height = "300px")
                                                                            )} 
                                                                        />
                                                                    </span>
                                                                </a>
                                                            </span>
                                                        </span>
                                                        <span className="d-flex mt-3">
                                                            {loanData?.aadhar}
                                                            <span className="thumbwrap-img mx-4">
                                                                <a className="thumb-img thumb-img-aadhar" href="#">
                                                                    <img width={60} height={60} className="thumb-base-img"
                                                                        src={loanData?.aadharFrontPath} alt="Aadhar Front"
                                                                        onError={(e) => (
                                                                            (e.target.src = "/assets/images/noimage.jpg"),
                                                                            (e.target.style.width = "40px"),
                                                                            (e.target.style.height = "40px")
                                                                        )} 
                                                                    />
                                                                    <span>
                                                                        <img className="hovered-img" src={loanData?.aadharFrontPath} alt=""
                                                                            onError={(e) => (
                                                                                (e.target.src = "/assets/images/noimage.jpg"),
                                                                                (e.target.style.width = "300px"),
                                                                                (e.target.style.height = "300px")
                                                                            )}
                                                                        />
                                                                    </span>
                                                                </a>
                                                            </span>
                                                            <span className="thumbwrap-img">
                                                                <a className="thumb-img thumb-img-aadhar" href="#">
                                                                    <img width={60} height={60} className="thumb-base-img" src={loanData?.aadharBackPath} alt="Aadhar Back" onError={(e) => (
                                                                            (e.target.src = "/assets/images/noimage.jpg"),
                                                                            (e.target.style.width = "40px"),
                                                                            (e.target.style.height = "40px")
                                                                        )}  />
                                                                    <span>
                                                                        <img className="hovered-img" src={loanData?.aadharBackPath} alt="" onError={(e) => (
                                                                            (e.target.src = "/assets/images/noimage.jpg"),
                                                                            (e.target.style.width = "300px"),
                                                                            (e.target.style.height = "300px")
                                                                        )} />
                                                                    </span>
                                                                </a>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-3 col-md-6">
                                            <strong className="header-text px-2">Summary</strong>
                                            <div className="container my-2">
                                                <div className="d-flex">
                                                    <div className="status-labels pr-5">
                                                        <p>Product Name:</p>
                                                        <p>Loan Amount: </p>
                                                        <p>Loan Tenure: </p>
                                                        <p>Down Payment:</p>
                                                    </div>
                                                    <div className="values">
                                                        <p>{loanData?.productName}</p>
                                                        <p><i className="fa fa-inr text-success"></i>{" "}{loanData?.loanAmount}</p>
                                                        <p>{loanData?.loanTenure}</p>
                                                        <p><i className="fa fa-inr text-success"></i>{" "}{loanData?.downPayment}</p>
                                                    </div>
                                                </div>
                                                <div className="form">
                                                    <Form>
                                                        {statusType === 'Approve' ? (
                                                            <div className="row">
                                                                <div className="status-labels col-md-3">Investor: </div>
                                                                <div className="col-md-9">
                                                                    <select
                                                                        className="form-control"
                                                                        name="investorId"
                                                                        value={inputValues?.investorId || ''}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option disabled value="">:: Select ::</option>
                                                                        {
                                                                            investorList?.map((item, i) => (
                                                                                <option value={item.userID} key={i}>
                                                                                    {item?.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        ): ""}
                                                        {statusType !== 'View' ? (
                                                            <div className="row mt-2">
                                                                <div className="status-labels col-md-3">Remark: </div>
                                                                <div className="col-md-9">
                                                                    <input
                                                                        type="text"
                                                                        name="remark"
                                                                        value={inputValues?.remark}
                                                                        className="form-control"
                                                                        onChange={handleChange}
                                                                        placeholder="Please Enter Your Remark"
                                                                        >
                                                                    </input>
                                                                </div>
                                                            </div>
                                                        ): ""}
                                                        <div className="hide-on-print-screen mt-3 d-flex justify-content-start">
                                                            {statusType === 'Approve' ? (
                                                                <botton className="btn btn-success" onClick={handleSubmit}>
                                                                    Approve
                                                                </botton>
                                                            ) : statusType === 'Reject' ? (
                                                                <botton className="btn btn-danger" onClick={handleSubmit}>
                                                                    Reject
                                                                </botton>
                                                            ) : (
                                                                <botton className="btn btn-info" onClick={handlePrintScreen}>
                                                                    Print
                                                                </botton>
                                                            )}
                                                            <botton className="btn btn-secondary mx-3" onClick={handleCancel}>
                                                                {statusType === 'View' ? "Back": "Cancel"}
                                                            </botton>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="investor p-3">
                                    <select className="form-control">
                                        {
                                            investorList?.map((item, i) => (
                                                <option value={item.id} key={i}>
                                                    {item?.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div> */}
                                    
                                </div>
                            ) : (
                                <div className="card p-3 cus-height">
                                    No Data found
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChangeLoanStatus;
