import DataTable from "react-data-table-component"
import Skeleton from "react-loading-skeleton"

const BtRdList = ({
        handleCardClick,
        tableLoader,
        rdList,
        accountStatement,
        accountStatementColumns,
        selectedCardRd,
    }) => {

    return (
        <>
            <div className="application-list row">
                <div className="col-md-6 p-0 pl-md-4">
                    <div className="loan-status-filter row">
                        <div className="col-md-4">
                            <strong className="header-text px-2">RD DETAILS</strong>
                        </div>
                    </div>
                    <div className="py-3 px-2 row application-list-business">
                        {rdList?.map((elem) => (
                            <>
                                <div className="col-lg-12 m-auto card-click-over" onClick={(e) => handleCardClick(e, elem?.savingId, true)}>
                                    <div class={"card overflow-hidden shadow-lg border-0" + (selectedCardRd === elem?.savingId ? " selected-div-style": "")}>
                                        <div className="card-body blogGridSlider">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="blog-item-card">
                                                        <div className="blog-title">
                                                            Customer Name:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.customerName}
                                                        </div>
                                                        <div className="blog-title">
                                                            Customer Email:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.customerEmailId}
                                                        </div>
                                                        <div className="blog-title">
                                                            Customer Mobile:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.customerMobile}
                                                        </div>
                                                        <div className="blog-title">
                                                            Customer Address:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.customerAddress}
                                                        </div>
                                                        <div className="blog-title">
                                                            Current Balance:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.currentBalance}
                                                        </div>
                                                        <div className="blog-title">
                                                            Duration:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.duration}
                                                        </div>
                                                        <div className="blog-title">
                                                            Maturity Amount:
                                                        </div>
                                                        <div className={(selectedCardRd === elem?.savingId ? "": "text-muted ") + "blog-text"}>
                                                            {elem?.maturityAmount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div className="col-md-6 p-0 pl-md-4">
                    {
                        rdList?.length > 0 ? (
                            <>
                                <strong className="header-text px-2">ACCOUNT STATEMENTS</strong>
                                {
                                    tableLoader ? (
                                        <>
                                            <p className="d-flex justify-content-between mt-2">
                                                <Skeleton width={50} height={35} />
                                                <Skeleton width={130} height={35} />
                                                <Skeleton width={70} height={35} className="mr-4" />
                                                <Skeleton width={80} height={35} />
                                            </p>
                                            {
                                                [0, 1, 2, 3, 4, 5, 6].map((item, i) => (
                                                    <p className="d-flex justify-content-between mt-3">
                                                        <Skeleton width={40} height={20} />
                                                        <Skeleton width={110} height={20} />
                                                        <Skeleton className="mr-5" width={60} height={20} />
                                                        <Skeleton width={70} height={20} />
                                                    </p>
                                                ))
                                            }
                                        </>
                                    ) : (
                                        <DataTable
                                            columns={accountStatementColumns}
                                            data={accountStatement}
                                            keyField="objectId"
                                            responsive
                                            highlightOnHover
                                            fixedHeader
                                            fixedHeaderScrollHeight="calc(100vh - 240px)"
                                        />
                                    )
                                }
                            </>
                        ) : ""
                    }
                </div>
            </div>
        </>
    )
}

export default BtRdList