import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import PasswordService from "./PasswordService";
const ChangePassword = () => {
  const {
    showPassword,
    setShowPassword,
    showCRPassword,
    setShowCRPassword,
    showCFPassword,
    setShowCFPassword,
    errorObj,
    handleChange,
    handleSubmit,
    isLoading,
    inputValues
  } = PasswordService();
  return (
    <div className="authentication no-authentication">
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Change Password</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            Change Password
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix cus-height">
            <div className="col-lg-12">
              <div className="card">
                {/* <div className="header">
                  <div className="row">
                    <div className="col-md-9">
                      <h2>
                        <strong>Change Password</strong>
                      </h2>
                    </div>
                  </div>
                </div> */}
                <div className="body">
                  <Form onSubmit={handleSubmit} className="form-div">
                    <Row>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Label>Current password : </Form.Label>
                          <div className="password-toggle">
                            <Form.Control
                              onChange={handleChange}
                              name="currentPassword"
                              value={inputValues?.currentPassword}
                              type={showCRPassword ? "text" : "password"}
                              placeholder="Enter Current password"
                              />
                            <div
                              className="password-toggle-icon"
                              onClick={() => setShowCRPassword(!showCRPassword)}
                            >
                              {!showCRPassword ? (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                          <span>{errorObj?.currentPassword}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Label>New Password : </Form.Label>
                          <div className="password-toggle">
                            <Form.Control
                              onChange={handleChange}
                              name="newPassword"
                              value={inputValues?.newPassword}
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter New Password"
                            />
                            <div
                              className="password-toggle-icon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {!showPassword ? (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                          <span>{errorObj?.newPassword}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Label>Confirm Password : </Form.Label>
                          <div className="password-toggle">
                            <Form.Control
                              onChange={handleChange}
                              name="confirmPassword"
                              type={showCFPassword ? "text" : "password"}
                              value={inputValues?.confirmPassword}
                              placeholder="Enter Confirm Password"
                            />
                            <div
                              className="password-toggle-icon"
                              onClick={() =>
                                setShowCFPassword(!showCFPassword)
                              }
                            >
                              {!showCFPassword ? (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                          <span>{errorObj?.confirmPassword}</span>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                        {/* <button
                          type="button"
                          className="btn btn-danger btn-round mr-2"
                        >
                        Cancel
                      </button> */}
                      <Button
                        type="submit"
                        className="btn btn-primary btn-round btn-width"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;
