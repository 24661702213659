import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "../../../Services/apiServices";
import { DataConstant } from "../../../Services/DataConstant";
import { toast } from "react-toastify";

const ChangeLoanStatusServices = (setPageLoading ) => {

  const [loanData, setLoanData] = useState({});
  const [investorList, setInvestorList] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const { state } = useLocation();
  const { statusType, id, redirectUrl } = state || {};
  const navigate = useNavigate();

  const getLoanDataById = async () => {
    try {
      if(!id) {
        return
      }
      setPageLoading(true);
      var postResponse = await postRequest(`LoanApplication/GetLoanApplicationsbyid/${id}`);
      if(postResponse) {
        setLoanData(postResponse);
        setInputValues({investorId: postResponse?.investorId})
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }

  const getInvestorList = async () => {
    try {
      setPageLoading(true);
      const postResponse = await postRequest(DataConstant.INVESTORLIST, {});
      if (postResponse?.statusCode === 1) {
        setInvestorList(postResponse?.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  const handleChange = async (e) => {
    try {
      const {name, value} = e.target;
      setInputValues((old) => {
        return {
          ...old,
          [name]: value
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async () => {
    try {
      if (!inputValues?.remark) {
        return toast.error("Please enter remark.", {toastId: "invalid-remark"});
      }
      if (!inputValues?.investorId) {
        return toast.error("Please select investor.", {toastId: "invalid-investor"});
      }
      let obj = {
        loanId: id,
        status: statusType === 'Approve' ? 1: 2,
        remark: inputValues.remark,
        investorId: +inputValues.investorId,
        entryBy: 0,
      };
      const resData = await postRequest(
        "LoanApplication/ChangeLoanApproveStatus",
        obj
      );
      if (resData?.statusCode == 1) {
        toast.success(resData?.responseText || "Status updated.");
        navigate(redirectUrl);
      } else {
        toast.error(resData?.responseText || "Failed to update status.", {
          toastId: "Failed-application",
        });
      }
    } catch (err) {
      console.log("error=>", err);
      toast.error("Something went wrong.");
    }
  };

  const handleCancel = () => {
    navigate(redirectUrl);
  }

  const handlePrintScreen = () => {
    try {
      window.print();     
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getLoanDataById();
    getInvestorList();
  }, [])

  return {
    loanData,
    statusType,
    investorList,
    handleChange,
    inputValues,
    handleSubmit,
    handleCancel,
    handlePrintScreen
  }

};

export default ChangeLoanStatusServices;