import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductService from "./ProductService";
import CategoriesServices from "../Categories/CategoriesServices";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EditProduct = ({ setPageLoading }) => {
  const {
    input,
    errorObj,
    handleChange,
    handleSubmit,
    handleImage,
    getByIdProductList,
    isLoading,
  } = ProductService();
  const { getCategoryList, categoryList } = CategoriesServices(setPageLoading);
  const param = useParams();

  useEffect(() => {
    categoryList();
    if (param.Id) {
      getByIdProductList();
    }
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-9 col-md-8 col-sm-12">
                        <h2>Product</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            Add Product
                          </li>
                        </ul>

                        {/* <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                          <Link to="/ProductByCategory">
                            <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                              PRODUCT LIST BY CATEGORY
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="authentication no-authentication">
            <div className="col-md-12 content-center content-center-sign-up">
              <div className="row clearfix mb-4">
                <div className="col-lg-12 m-auto">
                  <div className="card-plain card-plain-sign-up">
                    <Form onSubmit={handleSubmit} className="form-div">
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Category : </Form.Label>
                            <select
                              className="form-control"
                              name="categoryId"
                              value={input?.categoryId}
                              onChange={handleChange}
                            >
                              <option value="">SELECT</option>
                              {getCategoryList?.map((item, i) => (
                                <option key={i} value={item?.categoryId}>
                                  {item?.categoryName}
                                </option>
                              ))}
                            </select>
                            <span>{errorObj?.categoryId}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Product Title : </Form.Label>
                            <Form.Control
                              type="text"
                              name="productTitle"
                              value={input?.productTitle}
                              onChange={handleChange}
                              placeholder="Product Title"
                            />
                            <span>{errorObj?.productTitle}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Product Name : </Form.Label>
                            <Form.Control
                              type="text"
                              name="productName"
                              value={input?.productName}
                              onChange={handleChange}
                              placeholder="Product Name"
                            />
                            <span>{errorObj?.productName}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Quantity : </Form.Label>
                            <Form.Control
                              type="text"
                              name="quantity"
                              value={input?.quantity}
                              onChange={handleChange}
                              placeholder="Quantity"
                            />
                            <span>{errorObj?.quantity}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>MRP : </Form.Label>
                            <Form.Control
                              type="text"
                              name="mrp"
                              value={input?.mrp}
                              onChange={handleChange}
                              placeholder="MRP"
                            />
                            <span>{errorObj?.mrp}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Purchase Cost : </Form.Label>
                            <Form.Control
                              type="text"
                              name="purchaseCost"
                              value={input?.purchaseCost}
                              onChange={handleChange}
                              placeholder="Purchase Cost"
                            />
                            <span>{errorObj?.purchaseCost}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Selling Price : </Form.Label>
                            <Form.Control
                              type="text"
                              name="sellingPrice"
                              value={input?.sellingPrice}
                              onChange={handleChange}
                              placeholder="Selling Price "
                            />
                            <span>{errorObj?.sellingPrice}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Franchise Commision: </Form.Label>
                            <Form.Control
                              type="text"
                              name="franchiseCommision"
                              value={input?.franchiseCommision}
                              onChange={handleChange}
                              placeholder="Franchise Commision"
                            />
                            <span>{errorObj?.franchiseCommision}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Image : </Form.Label>
                            <Form.Control
                              type="file"
                              name="imageData"
                              onChange={handleImage}
                            />
                            <span>{errorObj?.imageData}</span>
                            {/* <img
                              className="d-block"
                              style={{ minHeight: "120px", maxWidth: "120px" }}
                              src={input?.imageData}
                              class="card-img-top"
                            /> */}
                          </div>
                        </Col>
                        <Col md={6} className="align-items-end d-flex">
                          <div className="form-group d-flex align-items-center">
                            <Form.Label>IsPublished : </Form.Label>
                            <Form.Control
                              name="isPublished"
                              type="checkbox"
                              checked={input?.isPublished}
                              onChange={handleChange}
                              className="cus-check"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3 form-group">
                            <Form.Label>Product Description : </Form.Label>
                            <textarea
                              className="form-control"
                              rows="3"
                              name="productDescription"
                              value={input?.productDescription}
                              onChange={handleChange}
                            ></textarea>
                            <span>{errorObj?.productDescription}</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/ProductLists"
                          className="btn btn-danger btn-round mr-2"
                        >
                          Cancel
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-primary btn-round btn-width"
                          disabled={isLoading}
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditProduct;
