import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddLoanService from "./AddLoanService";

const AddLoan = ({setPageLoading}) => {
  const {
    collapsed,
    handleChange,
    handleChange2,
    handleProceedClick,
    handleSubmitApplication,
    inputValues2,
    inputValues,
    errorObj,
    userList,
    product,
    isLoading,
    isPendingList,
    loanData,
  } = AddLoanService(setPageLoading);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="content-center content-center-sign-up">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="block-header">
                      <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12">
                          <h2>New Applications</h2>
                          <ul className="breadcrumb p-l-0 p-b-0 ">
                            <li className="breadcrumb-item">
                              <Link to="/">
                                <i className="fa fa-home"></i>
                              </Link>
                            </li>
                            <li className="breadcrumb-item active">
                              New Applications
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                          <Link to="/LoanApplicationList">
                            <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                              Application list
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cus-height">
              <div className="card">
                <div className="card-body">
                  <div className="flex flex-column mb-5 mt-4 faq-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div id="accordion">
                          <div className="card">
                            <div className="card-header" id="heading-1">
                              <h5 className="mb-0 cus-h5">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  href="#collapse-1"
                                  aria-expanded="true"
                                  aria-controls="collapse-1"
                                >
                                  Customer Details
                                  <span>
                                    <i
                                      className="fa fa-plus float-right"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-1"
                              className={`collapse ${
                                collapsed === 1 ? "show" : ""
                              }`}
                              data-parent="#accordion"
                              aria-labelledby="heading-1"
                            >
                              <div className="card-body">
                                <div>
                                  <div className="row clearfix mb-4">
                                    <div className="col-lg-12 m-auto">
                                      <div className="card-plain card-plain-sign-up">
                                        <Form
                                          onSubmit={handleSubmitApplication}
                                          className="form-div"
                                        >
                                          <Row>
                                            <Col md={6}>
                                              <Form.Group className="mb-3 form-group">
                                                <Form.Label>Name : </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={inputValues2?.name}
                                                  name="name"
                                                  type="text"
                                                  placeholder="Enter your Name"
                                                />
                                                <span>{errorObj?.name}</span>
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-3 form-group">
                                                <Form.Label>Email : </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={inputValues2?.emailId}
                                                  name="emailId"
                                                  type="text"
                                                  placeholder="Enter your Email"
                                                />
                                                <span>{errorObj?.emailId}</span>
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Mobile Number :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={inputValues2?.mobile}
                                                  name="mobile"
                                                  type="text"
                                                  maxLength={10}
                                                  placeholder="Enter your Mobile Number"
                                                />
                                                <span>{errorObj?.mobile}</span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Aadhar Number :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={inputValues2?.aadhar}
                                                  name="aadhar"
                                                  type="text"
                                                  maxLength={12}
                                                  placeholder="Aadhar number"
                                                />
                                                <span>{errorObj?.aadhar}</span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Pan Number :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={inputValues2?.pan}
                                                  name="pan"
                                                  type="text"
                                                  placeholder="Pan Number"
                                                />
                                                <span>{errorObj?.pan}</span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Alternate Number :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.alternateMobile
                                                  }
                                                  name="alternateMobile"
                                                  type="text"
                                                  placeholder="Alternate Number"
                                                />
                                                <span>
                                                  {errorObj?.alternateMobile}
                                                </span>
                                              </div>
                                            </Col>

                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Mailing Address :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.mailingAddress
                                                  }
                                                  name="mailingAddress"
                                                  type="text"
                                                  placeholder="Mailing Address"
                                                />
                                                <span>
                                                  {errorObj?.mailingAddress}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Mailing Address Pincode :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.postalCode_M
                                                  }
                                                  name="postalCode_M"
                                                  type="text"
                                                  placeholder="Mailing Address Pincode"
                                                />
                                                <span>
                                                  {errorObj?.postalCode_M}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Permanent Address :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.parmanentAddress
                                                  }
                                                  name="parmanentAddress"
                                                  type="text"
                                                  placeholder="Permanent Address"
                                                />
                                                <span>
                                                  {errorObj?.parmanentAddress}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  {" "}
                                                  Permanent Address Pincode :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.postalCode_P
                                                  }
                                                  name="postalCode_P"
                                                  type="text"
                                                  placeholder="Pincode"
                                                />
                                                <span>
                                                  {errorObj?.postalCode_P}
                                                </span>
                                              </div>
                                            </Col>
                                            {/* <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  {" "}
                                                  Pan Image :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.postalCode_P
                                                  }
                                                  name="postalCode_P"
                                                  type="file"
                                                  placeholder="Pincode"
                                                />
                                                <span>
                                                  {errorObj?.postalCode_P}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  {" "}
                                                  Aadhar Front :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.postalCode_P
                                                  }
                                                  name="postalCode_P"
                                                  type="file"
                                                  placeholder="Pincode"
                                                />
                                                <span>
                                                  {errorObj?.postalCode_P}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  {" "}
                                                  Aadhar Back :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange2}
                                                  value={
                                                    inputValues2?.postalCode_P
                                                  }
                                                  name="postalCode_P"
                                                  type="text"
                                                  placeholder="Pincode"
                                                />
                                                <span>
                                                  {errorObj?.postalCode_P}
                                                </span>
                                              </div>
                                            </Col> */}
                                          </Row>
                                          <div className="d-flex justify-content-end">
                                            <Link
                                              to={isPendingList ? "/PendingLoanApplication": "/LoanApplicationList"}
                                              className="btn btn-danger btn-round mr-2"
                                            >
                                              Cancel
                                            </Link>
                                            <Button
                                              // type="submit"
                                              className="btn btn-primary btn-round btn-width"
                                              data-toggle="collapse"
                                              onClick={handleProceedClick}
                                              // href="#collapse-2"
                                              aria-expanded="false"
                                              aria-controls="collapse-2"
                                            >
                                              Proceed
                                            </Button>
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header" id="heading-2">
                              <h5 className="mb-0 cus-h5">
                                <a
                                  className="collapsed"
                                  role="button"
                                  data-toggle="collapse"
                                  href="#collapse-2"
                                  aria-expanded="false"
                                  aria-controls="collapse-2"
                                >
                                  Loan Details
                                  <span>
                                    <i
                                      className="fa fa-plus float-right"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-2"
                              data-parent="#accordion"
                              className={`collapse ${
                                collapsed === 2 ? "show" : ""
                              }`}
                              aria-labelledby="heading-2"
                            >
                              <div class="card-body">
                                <div>
                                  <div className="row clearfix mb-4">
                                    <div className="col-lg-12 m-auto">
                                      <div className="card-plain card-plain-sign-up">
                                        <Form
                                          onSubmit={handleSubmitApplication}
                                          className="form-div"
                                        >
                                          <Row>
                                            <Col md={6}>
                                              <Form.Group className="mb-3 form-group">
                                                <Form.Label>
                                                  Down Payment :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange}
                                                  value={inputValues?.downPayment}
                                                  name="downPayment"
                                                  type="text"
                                                  disabled={loanData ? true : false}
                                                  placeholder="Down Payment"
                                                />
                                                <span>
                                                  {errorObj?.downPayment}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Loan Tenure :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange}
                                                  value={inputValues?.loanTenure}
                                                  name="loanTenure"
                                                  type="text"
                                                  placeholder="Loan Tenure"
                                                />
                                                <span>
                                                  {errorObj?.loanTenure}
                                                </span>
                                              </div>
                                            </Col>
                                            {/* <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Invoice No :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange}
                                                  value={inputValues?.invoiceNo}
                                                  name="invoiceNo"
                                                  type="text"
                                                  placeholder="Invoice No"
                                                />
                                                <span>{errorObj?.invoiceNo}</span>
                                              </div>
                                            </Col> */}

                                            {/* <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Batch No :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange}
                                                  value={inputValues?.batchNo}
                                                  name="batchNo"
                                                  type="text"
                                                  placeholder="batchNo"
                                                />
                                                <span>{errorObj?.batchNo}</span>
                                              </div>
                                            </Col> */}
                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Product Name :{" "}
                                                </Form.Label>
                                                <select
                                                  className="form-control"
                                                  name="productName"
                                                  onChange={handleChange}
                                                  value={inputValues?.productName}
                                                >
                                                  <option
                                                    disabled
                                                    defaultValue=""
                                                    value=""
                                                  >
                                                    Select
                                                  </option>
                                                  {product?.map((item) => (
                                                    <option
                                                      key={item.id}
                                                      field={item.productName}
                                                      value={item.id}
                                                    >
                                                      {item.productName}
                                                    </option>
                                                  ))}
                                                </select>
                                                {/* <Form.Control onChange={handleChange} name="productName" type="text" placeholder="Product Name" /> */}
                                                <span>
                                                  {errorObj?.productName}
                                                </span>
                                              </div>
                                            </Col>

                                            {/* <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>Brand : </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange}
                                                  value={inputValues?.brand}
                                                  name="brand"
                                                  type="text"
                                                  placeholder="Brand"
                                                />
                                                <span>{errorObj?.brand}</span>
                                              </div>
                                            </Col> */}
                                            {/* <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Warrenty (In month) :{" "}
                                                </Form.Label>
                                                <Form.Control
                                                  onChange={handleChange}
                                                  value={inputValues?.warrenty}
                                                  name="warrenty"
                                                  type="text"
                                                  placeholder="Warrenty (In month)"
                                                />
                                                <span>{errorObj?.warrenty}</span>
                                              </div>
                                            </Col> */}

                                            <Col md={6}>
                                              <div className="mb-3 form-group">
                                                <Form.Label>
                                                  Franchise Name :{" "}
                                                </Form.Label>
                                                <select
                                                  className="form-control"
                                                  name="franchiseId"
                                                  onChange={handleChange}
                                                  value={inputValues?.franchiseId}
                                                >
                                                  <option
                                                    disabled
                                                    defaultValue=""
                                                    value=""
                                                  >
                                                    Select
                                                  </option>
                                                  {userList?.map((item) => (
                                                    <option
                                                      key={item.userID}
                                                      field={item.franchiseName}
                                                      value={item.userID}
                                                    >
                                                      {item.franchiseName}
                                                    </option>
                                                  ))}
                                                </select>
                                                {/* <Form.Control onChange={handleChange} name="productName" type="text" placeholder="Product Name" /> */}
                                                <span>
                                                  {errorObj?.franchiseId ? 'Franchise Name is required': ''}
                                                </span>
                                              </div>
                                            </Col>
                                          </Row>
                                          <div className="d-flex justify-content-end">
                                            <Link
                                              to={isPendingList ? "/PendingLoanApplication": "/LoanApplicationList"}
                                              className="btn btn-danger btn-round mr-2"
                                            >
                                              Cancel
                                            </Link>
                                            <Button
                                              type="submit"
                                              className="btn btn-primary btn-round btn-width"
                                              disabled={isLoading}
                                            >
                                              {isLoading ? "Loading..." : "Apply"}
                                            </Button>
                                          </div>
                                        </Form>
                                        {/* <Link className="link" to="/Sign-in">You already have a account?</Link> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddLoan;
