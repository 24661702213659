const LoanStatus = {
  0: "Pending",
  1: "Approved",
  2: "Rejected",
};

const SearchType = {
  0: "None",
  1: "Franchise Mobile",
  2: "Investor Mobile",
  3: "Customer Mobile"
}

const TransactionType = {
  "c": "Credited",
  "C": "Credited",
  "D": "Debited",
  "d": "Debited",
}

const SlabSettingType = {
  "f": "Fixed",
  "p": "Percentage",
}
  
module.exports = {
  LoanStatus,
  SearchType,
  TransactionType,
  SlabSettingType
};
