import React from "react";
import { useState } from "react";
import { postRequest } from "../../../Services/apiServices";

const CustomerListService = (setPageLoading) => {
  const [customerList, setCutomerList] = useState([]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "80px",
    },

    {
      name: "NAME",
      selector: (row) => row.name,
      width: "150px",
    },

    {
      name: "EMAIL",
      selector: (row) => row.emailId,
      width: "200px",
    },
    {
      name: "ADDRESS",
      selector: (row) => row.parmanentAddress,
      width: "200px",
    },
    {
      name: "MOBILE",
      selector: (row) => row.mobile,
      width: "200px",
    },
    {
      name: "ALTERNATE No.",
      selector: (row) => row.alternateMobile,
      width: "200px",
    },
    {
      name: "POSTAL CODE_P",
      selector: (row) => row.postalCode_P,
      width: "200px",
    },
    {
      name: "POSTAL CODE_M",
      selector: (row) => row.postalCode_M,
      width: "200px",
    },
    {
      name: "MAILING ADDRESS",
      selector: (row) => row.mailingAddress,
      width: "200px",
    },
    {
      name: "PAN NO. ",
      selector: (row) => row.pan,
      width: "200px",
    },
    {
      name: "AADHAR No.",
      selector: (row) => row.aadhar,
      width: "200px",
    },
    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "200px",
    },
  ];

  const data = customerList?.map((d, i) => ({
    serial: i + 1,
    name: (
      <>
        <div className="text-center">
          {d?.name}
          <small className="d-block">[ID:{d?.userId}]</small>
        </div>
      </>
    ),
    emailId: d?.emailId,
    parmanentAddress: d?.parmanentAddress,
    mobile: d?.mobile,
    alternateMobile: d?.alternateMobile,
    postalCode_P: d?.postalCode_P,
    postalCode_M: d?.postalCode_M,
    mailingAddress: d?.mailingAddress,
    pan: d?.pan,
    aadhar: d?.aadhar,
    entryOn: d?.entryOn,
  }));

  const searchMobileNumber = async () => {
    const mobileNumber = document.getElementById("searchInput").value;
    await getCustomerList(mobileNumber);
  };

  const getCustomerList = async (mobileNumber) => {
    try {
      let requestData = {};
      if (mobileNumber) {
        requestData = { mobileNo: mobileNumber };
      } else {
        requestData = { mobileNo: "" };
      }
      setPageLoading(true);
      const res = await postRequest(`User/GetCustomerList`, requestData);
      if (res.statusCode === 1) {
        setCutomerList(res.result);
      }
      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      console.log(err);
    }
  };

  return { data, columns, getCustomerList, searchMobileNumber };
};

export default CustomerListService;
