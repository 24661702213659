import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import SignupService from "./SignupService";
import { Link } from "react-router-dom";

const Signup = () => {
  const { handleChange, handleSignUp, inputValues, errorObj, franchiseData, isLoading } =
    SignupService();
  return (
    <section className="content">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <h2>
                        {franchiseData
                          ? "Update Franchise"
                          : "Register Franchise"}
                      </h2>
                      <ul className="breadcrumb p-l-0 p-b-0 ">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="fa fa-home"></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          Franchise Management
                        </li>
                        {franchiseData ? (
                          <li className="breadcrumb-item active">
                            Update Franchise
                          </li>
                        ) : (
                          <li className="breadcrumb-item active">
                            Register Franchise
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="authentication no-authentication cus-height">
          <div className="col-md-12 content-center content-center-sign-up">
            <div className="row clearfix mb-4">
              <div className="col-lg-12 m-auto">
                <div className="card-plain card-plain-sign-up">
                  <Form onSubmit={handleSignUp} className="form-div">
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="franchiseName"
                            type="text"
                            value={inputValues?.franchiseName}
                            placeholder="Franchise Name"
                          />
                          <span>{errorObj?.franchiseName}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="emailId"
                            type="text"
                            value={inputValues?.emailId}
                            placeholder="Email"
                          />
                          <span>{errorObj?.emailId}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="mobileNumber"
                            value={inputValues?.mobileNumber}
                            type="text"
                            placeholder="Mobile Number"
                          />
                          <span>{errorObj?.mobileNumber}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="address"
                            value={inputValues?.address}
                            type="text"
                            placeholder="Address"
                          />
                          <span>{errorObj?.address}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="location"
                            value={inputValues?.location}
                            type="text"
                            placeholder="Location"
                          />
                          <span>{errorObj?.location}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="landMark"
                            type="text"
                            value={inputValues?.landMark}
                            placeholder="LandMark"
                          />
                          <span>{errorObj?.landMark}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="pinCode"
                            type="text"
                            value={inputValues?.pinCode}
                            placeholder="PinCode"
                          />
                          <span>{errorObj?.pinCode}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="gst"
                            value={inputValues?.gst}
                            type="text"
                            placeholder="GST"
                          />
                          <span>{errorObj?.gst}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="pan"
                            value={inputValues?.pan}
                            type="text"
                            placeholder="Pan"
                          />
                          <span>{errorObj?.pan}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 form-group">
                          <Form.Control
                            onChange={handleChange}
                            name="contactPerson"
                            type="text"
                            value={inputValues?.contactPerson}
                            placeholder="Contact Person"
                          />
                          <span>{errorObj?.contactPerson}</span>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <Link
                        to="/User-list"
                        className="btn btn-danger btn-round mr-2"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary btn-round btn-width"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="particles-js"></div> */}
    </section>
  );
};

export default Signup;
