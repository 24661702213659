import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import RangeWiseFeeService from "./RangeWiseFeeService";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const GetRangeWiseFee = ({ setPageLoading }) => {
  const {
    data,
    columns,
    getRangeWiseFeeList,
    showModal,
    handleClose,
    handleRemove,
  } = RangeWiseFeeService({ setPageLoading });

  useEffect(() => {
    getRangeWiseFeeList();
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Processing Fees List</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            Processing Fees Management
                          </li>

                          <li className="breadcrumb-item active">
                            Processing Fees List
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                        <Link to="/EditRangeFee">
                          <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                            Add New
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix cus-height">
            <div className="col-lg-12">
              <div className="card">
                {/* <div className="header">
                  <div className="row">
                    <div className="col-md-9">
                      <h2>
                        <strong>Processing Fees List</strong>
                      </h2>
                    </div>
                  </div>
                </div> */}
                <div className="body cus-table mt-2">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    keyField="objectId"
                    responsive
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20]}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 215px)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <Modal show={showModal} onHide={handleClose} size="sm">
            <Modal.Header>
              <span
                aria-hidden="true"
                onClick={handleClose}
                className="modal-cross"
              >
                &times;
              </span>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure to delete the data?</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleRemove}>
                Yes
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                NO
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </section>
    </div>
  );
};

export default GetRangeWiseFee;
