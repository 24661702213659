import { useState } from "react";
import { Capitalize } from "../../../Services/Common";
import { toast } from "react-toastify";
import { postRequest } from "../../../Services/apiServices";

const ForgotPasswordService = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    otp : "",
    phoneNumber: "",
    newPassword : ""
  };
  const [inputValues, setInputValues] = useState(initialState);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });

    setInputValues((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log('inputValues', inputValues);

      let hasError = false;
      // Check all form fields for errors
      Object.keys(inputValues).forEach((name) => {
        const value = inputValues[name];
        if (!value && (isOtp || (!isOtp && name === 'phoneNumber'))) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
        if (value && name === 'phoneNumber') {
          const phoneNumberPattern = /^\d{10}$/;
          if (!phoneNumberPattern.test(value)) {
            setErrorObj((old) => ({
              ...old,
              [name]: "Please enter a valid Phone number",
            }));
            hasError = true;
          }
        }
      });
      // If there are errors, prevent form submission
      if (hasError) {
        return;
      }
      setIsLoading(true);
      let res = {}
      if (!isOtp) {
        res = await postRequest(`SendForgotPasswordOTP/${inputValues.phoneNumber}`);
        if (res?.statusCode === 1) {
          setIsOtp(true);
          toast.success(res?.responseText || "Success.")
        } else {
          console.log(res?.responseText, res);
          toast.error(res?.responseText || "Failed to send OTP.")
        }
      } else {
        res = await postRequest(`ChangePassword`, inputValues);
        if (res?.statusCode === 1) {
          toast.success(res?.responseText || "Success.")
        } else {
          toast.error(res?.responseText || "Failed to reset password.")
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error("Failed to reset password.", {
        toastId: "Failed-reset-password",
      });
    }
  };
  return {
    showPassword,
    setShowPassword,
    errorObj,
    handleChange,
    handleSubmit,
    isLoading,
    inputValues,
    isOtp
  };
};

export default ForgotPasswordService;
