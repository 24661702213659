import React from "react";
import { useState } from "react";
import { getRequest, postRequest } from "../../Services/apiServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Capitalize } from "../../Services/Common";

const RangeWiseFeeService = ({ setPageLoading }) => {
  const initialState = {
    minAmount: "",
    maxAmount: "",
    processingFee: ""
  }
  const [getFee, setGetFee] = useState([]);
  const [input, setInput] = useState(initialState);
  const [errorObj, setErrorObj] = useState(initialState);
  const [getId, setGetId] = useState("");
  const navigate = useNavigate();
  const param = useParams();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      name: "#",
      cell: (row) => row.serial,
      width: "80px",
    },
    {
      name: "ACTIONS",
      cell: (row) => row.actions,
      width: "120px",
    },

    {
      name: <div className="cus-inr-text-header">MIN AMOUNT</div>,
      cell: (row) => row.minAmount,
      // width: "200px",
    },
    {
      name: <div className="cus-inr-text-header">MAX AMOUNT</div>,
      cell: (row) => row.maxAmount,
      // width: "200px",
    },
    {
      name: <div className="cus-inr-text-header">PROCESSING FEE</div>,
      cell: (row) => row.processingFee,
      // width: "200px",
    },
    {
      name: "ENTRY ON ",
      cell: (row) => row.entryOn,
      // width: "200px",
    },

    {
      name: "MODIFY ON ",
      cell: (row) => row.modifyOn,
      // width: "200px",
    },
  ];

  const data = getFee?.map((d, i) => ({
    serial: i + 1,
    actions: (
      <>
        <div className="custom-new-toggle-div">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
          ></button>
          <div className="dropdown-menu cus-toggle custom-new-toggle">
            <div className="cus-drop-down-list px-3">
              <Link className="py-2" 
                to={{
                  pathname: "/EditRangeFee/" + d?.id,
                }}
              >
                Edit
              </Link>
            </div>
            {/* <div className="cus-drop-down-list px-3">
              <div className="py-2" onClick={() => handlepopup(d?.id)}>
                Delete
              </div>
            </div> */}
          </div>
        </div>
      </>
    ),

    minAmount: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success"></i>{" "}
          {d?.minAmount}
        </div>
      </>
    ),
    maxAmount: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success"></i>{" "}
          {d?.maxAmount}
        </div>
      </>
    ),
    processingFee: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success"></i>{" "}
          {d?.processingFee}
        </div>
      </>
    ),
    entryOn: d?.entryOn,
    modifyOn: d?.modifyOn,
  }));

  /////****** GET PROCESSING FEE *////////

  const getRangeWiseFeeList = async () => {
    try {
      setPageLoading(true);
      const res = await getRequest("RangeWiseFee/GetAllAsync");
      setPageLoading(false);
      if (res.result) {
        setGetFee(res.result);
      }
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  };

  /////****** GET PROCESSING FEE *////////

  /////****** GET PROCESSING FEE BY ID *////////

  const rangeWiseFeeById = async () => {
    try {
      if (!param?.RangeId) {
        return
      }
      const res = await postRequest(
        `RangeWiseFee/GetByIdAsync/${param?.RangeId}`
      );
      if (res?.statusCode === 1) {
        setInput({
          minAmount: res.result.minAmount,
          maxAmount: res.result.maxAmount,
          processingFee: res.result.processingFee
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  /////****** GET PROCESSING FEE BY ID *////////

  /////****** DELETE PROCESSING FEE BY ID *////////

  const handlepopup = async (Id) => {
    setShowModal(true);
    setGetId(Id);
  };

  const handleClose = () => setShowModal(false);

  const handleRemove = async () => {
    try {
      const res = await postRequest(`RangeWiseFee/DeleteRangeAsync/${getId}`);
      if (res?.statusCode === 1) {
        getRangeWiseFeeList();
        setShowModal(false);
        toast.success(res?.responseText)
      } else {
        toast.error(res?.responseText)
      }
    } catch (error) {
      toast.error("Failed to delete.")
      console.log(error);
    }
  };

  /////****** DELETE PROCESSING FEE BY ID *////////

  /////****** EDIT PROCESSING FEE *////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/, "");
    setInput((prev) => {
      return { ...prev, [name]: numericValue };
    });
    setErrorObj((old) => {
      return {
        ...old,
        [name]: numericValue ? "" : Capitalize(name) + " is required",
      };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let hasError = false;
      // Check all form fields for errors
      Object.keys(input).forEach((name) => {
        const value = input[name];
        if (value === "" || value === null) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });
      if (hasError) {
        return;
      }
      setIsLoading(true);
      const res = await postRequest(
        `RangeWiseFee/AddAsync`,
        {
          MinAmount: input.minAmount,
          MaxAmount: input.maxAmount,
          ProcessingFee: input.processingFee,
          Id: param.RangeId || 0,
        },
        "multipart/form-data"
      );
      if (res?.statusCode === 1) {
        toast.success(res.responseText || "Success.");
        navigate("/GetRangeWiseFee");
      } else {
        toast.error(res?.responseText || "Failed to add range.");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  /////****** EDIT PROCESSING FEE *////////

  return {
    data,
    columns,
    getRangeWiseFeeList,
    input,
    handleChange,
    handleSubmit,
    rangeWiseFeeById,
    showModal,
    handleClose,
    handleRemove,
    isLoading,
    errorObj,
  };
};

export default RangeWiseFeeService;
