import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import ProductService from "./ProductService";
import { useEffect } from "react";
import CategoriesServices from "../Categories/CategoriesServices";
import { Col, Form } from "react-bootstrap";

const ProductLists = ({ setPageLoading }) => {
  const {
    data,
    columns,
    productListGet,
    showModal,
    handleClose,
    handleDelete,
    getCategoryIdValue,
    getProductByCategoryId,
    handleProdCatChange,
    handleStatusChange,
    statusValue,
  } = ProductService(setPageLoading);
  const { getCategoryList, categoryList } = CategoriesServices(setPageLoading);

  useEffect(() => {
    categoryList();
    // productListGet();
    getProductByCategoryId();
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="d-md-flex justify-content-between">
                      <div className="">
                        <h2> Product</h2>
                        <ul className="breadcrumb ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            Product List
                          </li>
                        </ul>
                      </div>

                      <div className="d-flex align-items-baseline">
                        <div className="mx-2">
                          <Form.Label>STATUS: </Form.Label>
                        </div>
                        <div className="form-group">
                          <select
                            className="custom-select font-style-select"
                            name="statusFilter"
                            value={statusValue}
                            onChange={handleStatusChange}
                          >
                            <option value="" disabled>:: Select Status ::</option>
                            <option value={-1}>All</option>
                            <option value={1}>Publish</option>
                            <option value={0}>Un Publish</option>
                          </select>
                        </div>
                      </div>

                      <div className="d-flex align-items-baseline">
                        <div className="mx-2">
                          <Form.Label>PRODUCT BY CATEGORY: </Form.Label>
                        </div>
                        <div className="">
                          <select
                            className="custom-select font-style-select"
                            name="categoryId"
                            value={getCategoryIdValue}
                            onChange={handleProdCatChange}
                          >
                            <option value="" >:: All Product ::</option>
                            {getCategoryList?.map((item) => (
                              <option key={item?.id}  value={item?.categoryId}>
                                {item?.categoryName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="">
                        <Link to="/EditProduct">
                          <button className="btn btn-primary btn-round btn-simple">
                            ADD
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix cus-height">
            <div className="col-lg-12">
              <div className="card">
                {/* <div className="header">
                  <h2>
                    <strong>Product List</strong>
                  </h2>
                </div> */}
                <div className="body cus-table mt-2">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    keyField="objectId"
                    responsive
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20]}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 215px)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showModal} size="sm" onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="mt-3">Confirmation</Modal.Title>
            <span
              aria-hidden="true"
              onClick={handleClose}
              className="modal-cross"
            >
              &times;
            </span>
          </Modal.Header>
          <Modal.Body className="pt-0">
            Aye you sure to delete Product!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
};

export default ProductLists;
