import React, { useState } from "react";
import { Capitalize } from "../../../Services/Common";
import { postRequest } from "../../../Services/apiServices";
import { isValidEmail } from "../../../Services/Validation";
import { toast } from "react-toastify";
const LoginService = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    emailId: "",
    password: "",
  });
  const [errorObj, setErrorObj] = useState({});

  const handleChange = async (e) => {
    const { value, name } = e.target;
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required.",
      };
    });
    if (name === "emailId" && value) {
      if (!isValidEmail(value)) {
        setErrorObj((old) => {
          return {
            ...old,
            [name]: "Please enter a valid email.",
          };
        });
      }
    }

    setInputValues((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      let hasError = false;

      // Check all form fields for errors
      Object.keys(inputValues).forEach((name) => {
        const value = inputValues[name];
        if (!value) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });
      // If there are errors, prevent form submission
      if (hasError || errorObj?.emailId) {
        return;
      }
      setIsLoading(true);

      const res = await postRequest(`Login`, inputValues);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
      if (res?.statusCode == 1) {
        if (res?.result?.role == "Admin") {
          toast.success(res.responseText);
          sessionStorage.setItem(".frontend.authToken", res?.result?.token);
          window.location.href = "/";
        } else {
          toast.error("Invalid credential.", { toastId: "error-login-admin" });
        }
      } else {
        toast.error(res?.responseText, { toastId: "error-login" });
      }
    } catch (e) {
      console.log("Failed to login.");
      setIsLoading(false);
      toast.error("Something went wrong.", { toastId: "failed-login" });
    }
  };
  return {
    showPassword,
    setShowPassword,
    isLoading,
    errorObj,
    handleChange,
    handleLogin,
  };
};

export default LoginService;
