import React, { useState } from "react";
import { postRequest } from "../../Services/apiServices";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Capitalize } from "../../Services/Common";
import { useEffect } from "react";

const RdPlanServices = (setPageLoading) => {

  const [rdPlanData, setRdPlanData] = useState([]);
  const [input, setInput] = useState({});
  const [errorObj, setErrorObj] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "80px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "150px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      width: "150px",
    },
    {
      name: "DURATION (In Months)",
      selector: (row) => row.duration,
      width: "160px",
    },
    {
      name: <div className="cus-inr-text-header">MATURITY AMOUNT</div>,
      cell: (row) => row.maturityAmount,
      width: "150px",
    },
    {
      name: <div className="cus-inr-text-header">PREMIUM</div>,
      cell: (row) => row.premium,
      width: "150px",
    },
  ];

  const data = rdPlanData?.map((d, i) => ({
    serial: i + 1,
    name: d?.name,
    maturityAmount: (
      <div className="cus-inr-text-value">
        <i className="fa fa-inr text-success"></i> 
        {" "}{d.maturityAmount}
      </div>
    ),
    duration: d?.duration,
    premium: (
      <div className="cus-inr-text-value">
        <i className="fa fa-inr text-success"></i> 
        {" "}{d.premium}
      </div>
    ),
    action: (
      <>
        <Link
          to={{
            pathname: "/RDPlanAdd",
          }}
          state= {{editData: d}}
        >
          <i title="Edit" className="fa fa-edit fa-2x text-info"></i>
        </Link>
      </>
    ),
  }));

  const rdPlanList = async () => {
    try {
      setPageLoading(true);
      const res = await postRequest(`RDPlan/GetAll`);
      if (res) {
        setRdPlanData(res);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let hasError = false;
      if (!input.name) {
        hasError = true;
        setErrorObj((old) => {
          return {
            ...old,
            ["name"]: "Name is required"
          }
        })
      }
      if (!input.maturityAmount) {
        hasError = true;
        setErrorObj((old) => {
          return {
            ...old,
            ["maturityAmount"]: "Maturity Amount is required"
          }
        })
      }
      if (!input.duration) {
        hasError = true;
        setErrorObj((old) => {
          return {
            ...old,
            ["duration"]: "Duration is required"
          }
        })
      }
      if (!input.premium) {
        hasError = true;
        setErrorObj((old) => {
          return {
            ...old,
            ["premium"]: "Premium is required"
          }
        })
      }
      if (hasError) {
        return;
      }
      setIsLoading(true);

      let res = await postRequest(
        `RDPlan/Add`,
        input,
      );

      if (res?.statusCode !== 1) {
        toast.error(res?.responseText || "Failed to add RD plan.");
      } else {
        navigate("/RdPlanList");
        toast.success(res.responseText || "Success");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to add RD plan.");
    }
  };
  
  useEffect(() => {
    if (state?.editData) {
      setInput(state.editData);
    }
  }, [])

  return {
    data,
    columns,
    rdPlanList,
    handleChange,
    handleSubmit,
    input,
    isLoading,
    errorObj
  };
};

export default RdPlanServices;
