import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { LoanStatus } from "../../../Services/interface";
import { postRequest } from "../../../Services/apiServices";

const PendingLoanService = (setPageLoading) => {
  const [applicationList, setApplicationList] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [status, setStatus] = useState();
  const [remark, setRemark] = useState("");

  const handleClose = () => setShow(false);

  const applicationColumns = [
    {
      name: "#",
      width: "40px",
      cell: (row, index) => {
        return (
          <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          row?.status === 0 ? (
            <div className="custom-new-toggle-div">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
              ></button>
              <div className="dropdown-menu cus-toggle custom-new-toggle">
                {/* <div className="cus-drop-down-list px-3 py-2">
                  <Link to="/AddLoanApplication" state={{ loanData: row?.id, isPendingList: true }}>
                    Edit
                    <i title="Edit" className="fa fa-edit fa-2x text-info mx-2"></i>
                  </Link>
                </div> */}
                <div className="cus-drop-down-list px-3">
                  <Link to="/ChangeLoanStatus" className="py-2" state={{ statusType: 'Approve', id: row?.id, redirectUrl: '/PendingLoanApplication' }}
                    // onClick={() => {
                    //   setShow(true);
                    //   setId(row?.id);
                    //   setStatus(1);
                    //   setRemark('');
                    // }}
                    // className="text-success"
                    // fa fa-check-square-o fa-2x 
                  >Approve</Link>
                </div>
                <div className="cus-drop-down-list px-3">
                  <Link to="/ChangeLoanStatus" className="py-2" state={{ statusType: 'Reject', id: row?.id, redirectUrl: '/PendingLoanApplication'  }}
                    // onClick={() => {
                    //   setShow(true);
                    //   setId(row?.id);
                    //   setStatus(2);
                    //   setRemark('');
                    // }}
                    // className="text-danger"
                    // fa fa-window-close-o fa-2x
                  >Reject</Link>
                </div>
              </div>
            </div>
          ): (
            <span className={row?.status === 1 ? "text-success": "text-danger"}>
              {LoanStatus[row?.status]}
            </span>
          )
        );
      },
    },
    {
      name: "Customer",
      width: "125px",
      cell: (row) => {
        return row?.customerName ? (
          <span>{row.customerName} <br /> 
            [<span className="user-info-ledger">{row.customerMobile}/{row.customerId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "Investor",
      width: "125px",
      cell: (row) => {
        return row?.investorName ? (
          <span>{row.investorName} <br /> 
            [<span className="user-info-ledger">{row.investorMobile}/{row.investorId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "franchise",
      width: "125px",
      cell: (row) => {
        return row?.franchiseName ? (
          <span>{row.franchiseName} <br /> 
            [<span className="user-info-ledger">{row.franchiseMobile}/{row.franchiseId}</span>]
          </span>
        ): "";
      },
    },
    {
      name: "Product Name",
      width: "125px",
      sortable: true,
      cell: (row) => {
        return row?.productName;
      },
    },
    {
      name: <div className="cus-inr-text-header">Loan Amount</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.loanAmount}
          </div>
        );
      },
    },
    {
      name: "Loan Tenure",
      width: "125px",
      cell: (row) => {
        return row?.loanTenure;
      },
    },
    {
      name: "InvoiceNo",
      cell: (row) => {
        return row?.invoiceNo || 'N/A';
      },
    },
    {
      name: <div className="cus-inr-text-header">MRP</div>,
      width: "130px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success"></i>
            {" "} {row?.mrp}
          </div>
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Selling Price</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.sellingPrice}
          </div>
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Processing Fee</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.processingFee}
          </div>
        );
      },
    },
    {
      name: <div className="cus-inr-text-header">Membership Fee</div>,
      width: "125px",
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.membershipFee}
          </div>
        );
      },
    },
    {
      name: "BatchNo",
      cell: (row) => {
        return row?.batchNo;
      },
    },
    {
      name: "Remark",
      cell: (row) => {
        return row?.remark;
      },
    },
    {
      name: "Apply On",
      cell: (row) => {
        return row?.entryOn;
      },
    },
  ];

  const getApplicationList = async () => {
    try {
      let fromDate = '1 Jul 2023';
      setPageLoading(true);
      const resData = await postRequest("LoanApplication/GetLoanApplications", {
        status: 0, // pending status
        textSearch: '',
        searchType: 0,
        toDate: moment(new Date()).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
        loginId: 0,
      });
      if (resData) {
        setApplicationList(resData);
      }
      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      console.log("error=>", err);
    }
  };

  const handleApprovedChange = async () => {
    try {
      if (!remark) {
        return;
      }
      let obj = {
        loanId: id,
        status,
        remark: remark || "",
        entryBy: 0,
      };

      const resData = await postRequest(
        "LoanApplication/ChangeLoanApproveStatus",
        obj
      );
      if (resData?.statusCode === 1) {
        setShow(false);
        getApplicationList();
        toast.success(resData?.responseText || "Status updated.");
      } else {
        toast.error(resData?.responseText || "Failed to update status.", {
          toastId: "Failed-application",
        });
      }
    } catch (err) {
      console.log("error=>", err);
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    getApplicationList();
  }, []);

  return {
    applicationColumns,
    handleApprovedChange,
    handleClose,
    applicationList,
    show,
    setRemark,
  };
};

export default PendingLoanService;
