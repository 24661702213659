import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "../../Services/apiServices";
import { Modal, Button } from "react-bootstrap";

import { toast } from "react-toastify";

const TopHeader = ({setPageLoading}) => {
  const [userInfo, setUserInfo] = useState({});
  const [funds, setFunds] = useState("");
  const [remark, setRemark] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const getUserInfo = async () => {
    try {
      const resData = await getRequest("User/UserInfo");
      if (resData) {
        setUserInfo(resData);
      }
    } catch (e) {
      console.log("error=>", e);
    }
  };

  const AddFundsToAdmin = async () => {
    try {
      if (!funds) {
        return toast.error("Please enter a valid amount", {
          toastId: "invalid_amount",
        });
      }
      if (!remark) {
        return toast.error("Please enter remark", {
          toastId: "invalid_reamrk",
        });
      }
      let obj = {
        amount: funds,
        trType: "c",
        remark: remark,
      };
      setPageLoading(true);
      const resData = await postRequest(`Fund/SelfWalletTransfer`, obj);
      if (resData.statusCode === 1) {
        toast.success(resData.responseText || "Wallet Credit Successfully");
        getUserInfo();
        handleClose();
        setFunds("");
      } else {
        toast.error(resData.responseText || "Failed to add funds.");
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
      toast.error("Something went wrong, while Adding funds.");
    }
  };

  const inputHandler = (e) => {
    const numericValue = e.target.value.replace(/\D/, "");
    setFunds(numericValue);
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <>
      <nav className="top_navbar">
        <div className="container">
          <div className="row clearfix">
            <div className="col-12">
              <div className="navbar-logo">
                <a href="javascript:void(0);" className="bars"></a>
                <a className="navbar-brand" href="#">
                  <img src="assets/images/logo.png" width="150" alt="InfiniO" />
                </a>
              </div>
              <ul
                className="nav navbar-nav hide-on-print-screen"
                style={{ flexDirection: "inherit" }}
              >
                <li className="dropdown profile">
                  <a
                    href="javascript:void(0);"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                  >
                    <img
                      className="rounded-circle"
                      src="assets/images/profile_av.jpg"
                      alt="User"
                    />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <div className="user-info">
                        <h6 className="user-name text-center m-b-0">
                          {userInfo.name || ""}
                        </h6>
                      </div>
                    </li>
                    <hr />
                    <button
                      className="btn btn-primary"
                      onClick={() => {setShow(true); setFunds(""); setRemark("");}}
                      style={{ marginLeft: "76px" }}
                    >
                      Add Funds
                    </button>

                    <hr />
                    <li>
                      <a href="#">
                        <i
                          className="icon-user m-r-10"
                          style={{ fontSize: "13px" }}
                          aria-hidden="true"
                        ></i>
                        <span>{"Role: " + (userInfo.role || "")}</span>
                      </a>
                    </li>

                    <li>
                      <a href="#">
                        <i
                          className="fa fa-mobile-phone m-r-10"
                          style={{ fontSize: "24px" }}
                          aria-hidden="true"
                        ></i>
                        <span>{"Mobile Number: " + (userInfo.mobileNo || "")}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          className="fa fa-inr m-r-10"
                          style={{ fontSize: "20px" }}
                          aria-hidden="true"
                        ></i>
                        <span>{"Balance: " + (userInfo.balance || 0)}</span>
                      </a>
                    </li>
                    <hr />
                    <li>
                      <Link to="/ChangePassword">
                        <i className="fa fa-key m-r-10"></i>
                        <span>Change Password</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SignOut">
                        <i className="icon-power m-r-10"></i>
                        <span>Sign Out</span>
                      </Link>
                    </li>
                    <hr />
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* <Modal show={show} size="sm" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title
            className="mt-3"
            style={{ display: "block", margin: "auto" }}
          >
            Add Funds{" "}
          </Modal.Title>
          <span
            aria-hidden="true"
            onClick={handleClose}
            className="modal-cross"
          >
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className="pt-0"> Amount : </Modal.Body>
        <textarea
          name="comments"
          className="cus-comments cus-input-focus"
          id="comments"
          value={funds}
          onChange={inputHandler}
          placeholder="Please Enter Your Amount"
        ></textarea>
        <Modal.Footer>
          <Button
            className="btn-danger"
            variant="secondary"
            onClick={AddFundsToAdmin}
          >
            Add Funds
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={show} size="" className="cus-modal-center" onHide={handleClose} centered>
        <div className="bg-primary text-white d-flex">
          <Modal.Title className="text-white my-2 mx-4">
            Add Funds
          </Modal.Title>
          <span aria-hidden="true" onClick={handleClose} className="modal-cross pt-2">
            &times;
          </span>
        </div>
        <Modal.Body>
          <div className="form-group">
            <input
              name="comments"
              className="form-control"
              value={funds}
              onChange={inputHandler}
              placeholder="Please Enter Your Amount"
            ></input>

            <input
              name="remark"
              className="form-control mt-3"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              placeholder="Please Enter Your Remark"
            ></input>
          </div>
        </Modal.Body>
        <div className="container pb-3">
          <Button className="btn-primary" variant="secondary" onClick={AddFundsToAdmin}>
            Add Funds
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TopHeader;
