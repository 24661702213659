import { useEffect, useState } from "react";
import { Capitalize } from "../../../Services/Common";
import { getRequest, postRequest } from "../../../Services/apiServices";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import validator from "validator";
import ListService from "../../UserManagement/Franchise/List/ListService";
const AddLoanService = (setPageLoading) => {
  const { userList } = ListService(setPageLoading);

  const initialState = {
    downPayment: "",
    loanTenure: "",
    // invoiceNo: "",
    // batchNo: "",
    productName: "",
    // brand: "",
    // warrenty: "",
    franchiseId: "",
    // loanAmount: 0,
    // franchiseId: ""
  };

  const initialState2 = {
    emailId: "",
    mobile: "",
    name: "",
    aadhar: "",
    pan: "",
    alternateMobile: "",
    mailingAddress: "",
    postalCode_M: "",
    parmanentAddress: "",
    postalCode_P: "",
  };
  const { state } = useLocation();
  const { loanData, isPendingList } = state || {};

  const [errorObj, setErrorObj] = useState(initialState);
  const [inputValues, setInputValues] = useState(initialState);
  const [inputValues2, setInputValues2] = useState(initialState2);
  const [collapsed, setCollapsed] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [product, setProduct] = useState([]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });

    setInputValues((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleChange2 = (e) => {
    const { value, name } = e.target;
    let error = "";
    switch (name) {
      case "emailId":
        error = !validator.isEmail(value) ? "Invalid Email" : "";
        break;
      case "mobile":
        error = !validator.isMobilePhone(value) ? "Invalid mobile" : "";
        break;
      case "aadhar":
        error =
          !validator.isNumeric(value) || value.length !== 12
            ? "Invalid Aadhar"
            : "";
        break;
      case "pan":
        error =
          !validator.isAlphanumeric(value) || value.length !== 10
            ? "Invalid Pan Number"
            : "";
        break;
      case "alternateMobile":
        error =
          !validator.isNumeric(value) || value.length !== 10
            ? "Invalid Mobile Number"
            : "";
        break;
      case "postalCode_P":
        error =
          !validator.isNumeric(value) || value.length !== 6
            ? "Invalid Pincode "
            : "";
        break;
      case "postalCode_M":
        error =
          !validator.isNumeric(value) || value.length !== 6
            ? "Invalid Pincode"
            : "";
        break;
      default: break;
    }

    // console.log("validator : ", name, validator.isEmail(value));
    setErrorObj((old) => {
      return {
        ...old,
        [name]: error ? error : "",
      };
    });

    setInputValues2((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleSubmitApplication = async (e) => {
    e.preventDefault();
    try {
      let hasError = false;
      // Check all form fields for errors
      Object.keys(inputValues).forEach((name) => {
        const value = inputValues[name];
        if (value === "" || value === null) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });

      // If there are errors, prevent form submission
      if (hasError) {
        return;
      }

      let obj = { ...inputValues, warrentyInMonth: inputValues?.warrenty };
      delete obj.warrenty;

      const selectedProduct = product.find(
        (item) => item.id === obj.productName
      );

      if (selectedProduct) {
        const { mrp, productName, sellingPrice, id } = selectedProduct;
        obj = { ...obj, mrp, productName, sellingPrice, productId: id };
      }

      if (isNaN(obj?.downPayment)) {
        setErrorObj({ downPayment: "Please enter valid amount" });
        return;
      }
      obj.status = 0;

      // let obj2 = { customerDetail: inputValues2, loanApplication: inputValues };

      let hasError2 = false;
      // Check all form fields for errors
      Object.keys(inputValues2).forEach((name) => {
        const value = inputValues2[name];
        if (value === "" || value === null) {
          hasError2 = true;
        }
      });

      // If there are errors, prevent form submission
      if (hasError2) {
        return toast.error("Please fill customer details");
      }

      const formData = new FormData();
      let newInput = {...inputValues2};
      newInput.panImage = "";
      newInput.aadharBack = "";
      newInput.aadharFront = "";
      for (let key in newInput) {
        formData.append(`customerDetail.${key}`, newInput[key]);
      }
      let firstObj = {...inputValues}
      firstObj.productId = +inputValues.productName
      delete firstObj.productName
      if (loanData) {
        firstObj.id = loanData;
      }

      for (let key in firstObj) {
        formData.append(`loanApplication.${key}`, firstObj[key]);
      }
      setIsLoading(true);
      const res = await postRequest(
        "LoanApplication/AddUpdateLoanApplcation",
        formData,
        "multipart/form-data"
      );

      if (res?.statusCode === 1) {
        toast.success(res?.responseText || "Successfully submitted.");
        setInputValues(initialState);
      } else {
        toast.error(res?.responseText || "Failed to submit application.", {
          toastId: "Failed-application",
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("error==>", "Failed to submit application", e);
      toast.error("Failed to submit application.", {
        toastId: "Failed-application",
      });
    }
  };

  const handleProceedClick = (e) => {
    let hasError = false;
    Object.keys(inputValues2).forEach((name) => {
      const value = inputValues2[name];
      if (value === "" || value === null) {
        setErrorObj((old) => ({
          ...old,
          [name]: Capitalize(name) + " is required",
        }));
        hasError = true;
      }
    });

    if (!hasError) {
      setCollapsed(2);
    }
  };

  const getProducts = async () => {
    try {
      const resData = await getRequest("Master_Product/GetAllAsync");
      if (resData?.statusCode === 1) {
        setProduct(resData.result);
      }
    } catch (err) {
      console.log("error=>", err);
    }
  };

  const getLoanById = async () => {
    try {
      let res = await postRequest(`LoanApplication/GetLoanApplicationsbyid/${loanData}`);
      if (res && res?.emailId) {
        setInputValues({
          downPayment: res.downPayment,
          loanTenure: res.loanTenure,
          productName: res.productId,
          franchiseId: res.franchiseId,
        });
        setInputValues2({
          emailId: res.emailId,
          mobile: res.mobile,
          name: res.name,
          aadhar: res.aadhar,
          pan: res.pan,
          alternateMobile: res.alternateMobile, 
          mailingAddress: res.mailingAddress,
          postalCode_M: res.postalCode_M,
          parmanentAddress: res.parmanentAddress,
          postalCode_P: res.postalCode_P,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProducts();
    if (loanData) {
      getLoanById();
    }
  }, []);

  return {
    collapsed,
    handleChange,
    handleChange2,
    handleProceedClick,
    handleSubmitApplication,
    getProducts,
    inputValues2,
    inputValues,
    errorObj,
    userList,
    product,
    isLoading,
    isPendingList,
    loanData
  };
};

export default AddLoanService;
