import React from "react";
import { ButtonSpinner } from "../../../Components/Loader/spinner";
import { Link } from "react-router-dom";
import ForgotPasswordService from './ForgotPasswordService'

const ForgotPassword = () => {
  const {
    showPassword,
    setShowPassword,
    isLoading,
    errorObj,
    handleChange,
    isOtp,
    handleSubmit,
  } = ForgotPasswordService();
  return (
    <>
      <div className="login-4">
        <div className="container-fluid">
          <div className="row login-box">
            <div className="col-lg-6 form-section">
              <div className="form-inner">
                <a href="login-4.html" className="logo">
                  <img src="/assets/images/whitelogo.png" alt="logo" />
                </a>
                <h3>Reset Password</h3>
                <form onSubmit={handleSubmit}>
                  <div className="form-group position-relative clearfix">
                    <input
                      name="phoneNumber"
                      onChange={handleChange}
                      type="text"
                      disabled={isOtp}
                      className="form-control"
                      placeholder="Enter Phone Number"
                    />
                    <span className="vaidation-error">{errorObj?.phoneNumber}</span>
                  </div>
                  {
                    isOtp ? (
                      <>
                        <div className="form-group position-relative clearfix">
                          <input
                            name="otp"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            placeholder="Enter Otp"
                          />
                          <span className="vaidation-error">{errorObj?.otp}</span>
                        </div>
                        <div className="form-group clearfix position-relative password-wrapper">
                          <div className="password-toggle">
                            <input
                              type={showPassword ? "text" : "password"}
                              onChange={handleChange}
                              name="newPassword"
                              className="form-control"
                              autocomplete="off"
                              placeholder="Enter New Password"
                            />
                            <div
                              className="password-toggle-icon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {!showPassword ? (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                              )}
                            </div>
                          </div>
                          <span className="vaidation-error">
                            {errorObj?.newPassword}
                          </span>
                        </div>
                      </>
                    ) : ""
                  }
                  <div className="form-group mb-0 d-flex justify-content-between align-items-center">
                    {isLoading ? (
                      <ButtonSpinner
                        isLoading={isLoading}
                        customClass="spinner-button"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-theme"
                      >
                        Reset Password
                      </button>
                    )}
                    <Link to="/" className="text-white">
                      Login ?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 bg-img clip-home h-100"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
