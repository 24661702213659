import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CategoriesServices from "./CategoriesServices";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EditCategories = ({ setPageLoading }) => {
  const {
    handleBanner,
    handleChange,
    handleIconImage,
    input,
    errorObj,
    handleSubmit,
    getByIdCategoryList,
    getCategoryList,
    categoryList,
    isLoading,
  } = CategoriesServices(setPageLoading);

  const param = useParams();

  useEffect(() => {
    if (param.CategoryId) {
      getByIdCategoryList();
    }
    categoryList();
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Category</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">Category</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="authentication no-authentication cus-height">
            <div className="col-md-12 content-center content-center-sign-up">
              <div className="row clearfix mb-4">
                <div className="col-lg-12 m-auto">
                  <div className="card-plain card-plain-sign-up">
                    <Form
                      onSubmit={handleSubmit}
                      className="form-div"
                      id="category-form"
                    >
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Parent : </Form.Label>
                            <select
                              className="form-control"
                              name="parentId"
                              value={input?.parentId}
                              onChange={handleChange}
                            >
                              <option value={0}>SELECT</option>
                              {getCategoryList?.map((item, i) => (
                                <option key={i} value={item?.categoryId}>
                                  {item?.categoryName}
                                </option>
                              ))}
                            </select>
                            <span>{errorObj?.parentId}</span>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="mb-3 form-group">
                            <Form.Label>Category Name : </Form.Label>
                            <Form.Control
                              type="text"
                              name="categoryName"
                              value={input?.categoryName}
                              onChange={handleChange}
                              placeholder="Category Name"
                            />
                            <span>{errorObj?.categoryName}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Tax Rate : </Form.Label>
                            <Form.Control
                              type="text"
                              name="taxRate"
                              value={input?.taxRate}
                              onChange={handleChange}
                              placeholder="TAX Rate in %"
                            />
                            <span>{errorObj?.taxRate}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Icon Image : </Form.Label>
                            <Form.Control
                              type="file"
                              id="iconImg"
                              name="iconImg"
                              onChange={handleIconImage}
                            />
                            <span>{errorObj?.iconImg}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 form-group">
                            <Form.Label>Banner : </Form.Label>
                            <Form.Control
                              type="file"
                              id="banners"
                              name="banners"
                              onChange={handleBanner}
                            />
                            <span>{errorObj?.banners}</span>
                          </div>
                        </Col>
                        <Col md={6} className="align-items-end d-flex">
                          <div className="form-group d-flex align-items-center">
                            <Form.Label>IsPublish : </Form.Label>
                            <Form.Control
                              name="isPublish"
                              type="checkbox"
                              checked={input?.isPublish}
                              onChange={handleChange}
                              className="cus-check"
                            />
                            <span>{errorObj?.isPublish}</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/CategoriesList"
                          className="btn btn-danger btn-round mr-2"
                        >
                          Cancel
                        </Link>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-round btn-width"
                          disabled={isLoading}
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditCategories;
