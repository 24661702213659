import Skeleton from "react-loading-skeleton"
import { LoanStatus } from "../../Services/interface"
import DataTable from "react-data-table-component"

const BtLoanList = ({
        handleGetInfo,
        applicationList,
        EMIData,
        handleCardClick,
        tableColumns,
        tableLoader,
        selectedCard,
    }) => {

    return (
        <>
            <div className="application-list row">
                <div className="col-md-6 p-0 pl-md-4">
                    <div className="loan-status-filter row">
                        {
                            applicationList?.length > 0 ? (
                                <>
                                    <div className="col-md-4">
                                        <strong className="header-text px-2">LOAN DETAILS</strong>
                                    </div>
                                    <div className="mb-2 form-group col-md-8 d-md-flex justify-content-between">
                                        <label className="ml-md-3 mt-1 col-md-4">
                                            Choose Status :
                                        </label>
                                        <select
                                            className="custom-select"
                                            onChange={(e) => handleGetInfo(e)}
                                        >
                                            <option value="-1" selected="selected">
                                                :: ALL ::
                                            </option>
                                            <option value="0">{LoanStatus[0]}</option>
                                            <option value="1">{LoanStatus[1]}</option>
                                            <option value="2">{LoanStatus[2]}</option>
                                        </select>
                                    </div>
                                </>
                            ) : ""
                        }
                    </div>
                    <div className="py-3 px-2 row application-list-business">
                        {applicationList?.map((item) => (
                            <>
                                <div className="col-lg-12 m-auto card-click-over" onClick={(e) => handleCardClick(e, item?.id)}>
                                    <div class={"card overflow-hidden shadow-lg border-0" + (selectedCard === item?.id ? " selected-div-style": "")}>
                                        <div className="card-body blogGridSlider">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="ms-4">
                                                        <span class={"badge font-size-large font-weight-bold py-2 px-3 text-uppercase " + (item?.status === 1 ? 'bg-soft-success text-success' : item?.status === 2 ? 'bg-soft-danger text-danger' : 'bg-soft-warning text-warning')}>
                                                            {LoanStatus[item?.status]}
                                                        </span>
                                                        <div className="blog-item-card">
                                                            <div className="blog-title">
                                                                Loan Id:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.id}
                                                            </div>
                                                            <div className="blog-title">
                                                                Amount:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.loanAmount}
                                                            </div>
                                                            <div className="blog-title">
                                                                Loan Tenure:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.loanTenure}
                                                            </div>
                                                            <div className="blog-title">
                                                                Down Payment:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.downPayment}
                                                            </div>
                                                            <div className="blog-title">
                                                                Product Name:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.productName}
                                                            </div>
                                                            <div className="blog-title">
                                                                Apply On:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.entryOn}
                                                            </div>
                                                            <div className="blog-title">
                                                                Modify On:
                                                            </div>
                                                            <div class={(selectedCard === item?.id ? "": "text-muted ") + "blog-text"}>
                                                                {item?.modifyOn}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div className="col-md-6 p-0 pl-md-4">
                    {
                        applicationList?.length > 0 ? (
                            <>
                                <strong className="header-text px-2">EMI DETAILS</strong>
                                {
                                    tableLoader ? (
                                        <>
                                            <p className="d-flex justify-content-between mt-2">
                                                <Skeleton width={50} height={35} />
                                                <Skeleton width={130} height={35} />
                                                <Skeleton width={70} height={35} className="mr-4" />
                                                <Skeleton width={80} height={35} />
                                            </p>
                                            {
                                                [0, 1, 2, 3, 4, 5, 6].map((item, i) => (
                                                    <p className="d-flex justify-content-between mt-3">
                                                        <Skeleton width={40} height={20} />
                                                        <Skeleton width={110} height={20} />
                                                        <Skeleton className="mr-5" width={60} height={20} />
                                                        <Skeleton width={70} height={20} />
                                                    </p>
                                                ))
                                            }
                                        </>
                                    ) : (
                                        <DataTable
                                            columns={tableColumns}
                                            data={EMIData}
                                            keyField="objectId"
                                            responsive
                                            highlightOnHover
                                            fixedHeader
                                            fixedHeaderScrollHeight="calc(100vh - 240px)"
                                        />
                                    )
                                }
                            </>
                        ) : ""
                    }
                </div>
            </div>
        </>
    )
}

export default BtLoanList