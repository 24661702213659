import { PulseLoader } from 'react-spinners';

export const ButtonSpinner = ({isLoading=false, customClass=''}) => {

    return (
        <button disabled className={customClass}>
            <PulseLoader 
                color="#fff"
                loading={isLoading}
                size={17}
            />
        </button>
    )
}
