import { useState } from "react";
import { Capitalize } from "../../../Services/Common";
import { toast } from "react-toastify";
import { postRequest } from "../../../Services/apiServices";

const PasswordService = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCRPassword, setShowCRPassword] = useState(false);
  const [showCFPassword, setShowCFPassword] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    currentPassword : "",
    newPassword : "",
    confirmPassword: ""
  };
  const [inputValues, setInputValues] = useState(initialState);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrorObj((old) => {
      return {
        ...old,
        [name]: value ? "" : Capitalize(name) + " is required",
      };
    });

    setInputValues((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      let hasError = false;
      // Check all form fields for errors
      Object.keys(inputValues).forEach((name) => {
        const value = inputValues[name];
        if (!value) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });
      // If there are errors, prevent form submission
      if (hasError) {
        return;
      }

      if (inputValues?.newPassword !== inputValues?.confirmPassword) {
        setErrorObj({ confirmPassword: "Password do not match" });
        return;
      }
      setIsLoading(true);

      const res = await postRequest(`User/ChangePassword`, inputValues);

      if (res?.statusCode === 1) {
        setInputValues(initialState);
        toast.success(res?.responseText || "Success.")
      } else {
        toast.error(res?.responseText || "Failed to update password")
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("error==>", e);
      toast.error("Failed to update password.", {
        toastId: "Failed-update-password",
      });
    }
  };
  return {
    showPassword,
    setShowPassword,
    showCRPassword,
    setShowCRPassword,
    showCFPassword,
    setShowCFPassword,
    errorObj,
    handleChange,
    handleSubmit,
    isLoading,
    inputValues,
  };
};

export default PasswordService;
