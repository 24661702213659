import React from "react";
import CategoriesServices from "./CategoriesServices";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const CategoriesList = ({ setPageLoading }) => {
  const { data, columns, categoryList, handleClose, handleDelete, showModal } =
    CategoriesServices(setPageLoading);
  useEffect(() => {
    categoryList();
  }, []);

  return (
    <div>
      <div>
        <section className="content">
          <div className="container">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="block-header">
                      <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12">
                          <h2>Category</h2>
                          <ul className="breadcrumb p-l-0 p-b-0 ">
                            <li className="breadcrumb-item">
                              <Link to="/">
                                <i className="fa fa-home"></i>
                              </Link>
                            </li>
                            <li className="breadcrumb-item active">
                              Category List
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                          <Link to="/EditCategories">
                            <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                              ADD
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row clearfix cus-height">
              <div className="col-lg-12">
                <div className="card">
                  {/* <div className="header">
                    <h2>
                      <strong>Category List </strong>
                    </h2>
                  </div> */}
                  <div className="body cus-table mt-2">
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      keyField="objectId"
                      responsive
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[10, 20]}
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 215px)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={showModal} size="sm" onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="mt-3">Confirmation</Modal.Title>
              <span
                aria-hidden="true"
                onClick={handleClose}
                className="modal-cross"
              >
                &times;
              </span>
            </Modal.Header>
            <Modal.Body className="pt-0">
              Aye you sure to delete category!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
    </div>
  );
};

export default CategoriesList;
