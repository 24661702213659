import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Pages/Dashboard/Dashboard.js";
import TopHeader from "./Components/Header/TopHeader.js";
import TopSubHeader from "./Components/Header/TopSubHeader.js";
import Login from "./Pages/Account/Login/Login";
import Footer from "./Components/Footer/Footer";
import List from "./Pages/UserManagement/Franchise/List/List";
import InvestorList from "./Pages/UserManagement/Investor/List/InvestorList";
import InvestorSignup from "./Pages/UserManagement/Investor/Signup/InvestorSignup";
import Signup from "./Pages/UserManagement/Franchise/Signup/Signup";
import Logout from "./Pages/Account/Logout/Logout";
import { ToastContainer } from "react-toastify";
import LoanList from "./Pages/LoanApplication/List/LoanList";
import AddLoan from "./Pages/LoanApplication/Add/AddLoan";
import { PageLoader } from "./Components/Loader/pageLoading";
import { useState } from "react";
import Ledger from "./Pages/Ledger/Ledger";
import EditRangeFee from "./Pages/RangeWiseFee/EditRangeFee";
import GetRangeWiseFee from "./Pages/RangeWiseFee/GetRangeWiseFee";
import CustomerList from "./Pages/UserManagement/CustomerList/CustomerList";
import LedgerReportList from "./Pages/LedgerReport/LedgerReportList";
import CategoriesList from "./Pages/Categories/CategoriesList";
import EditCategories from "./Pages/Categories/EditCategories";
import ProductLists from "./Pages/Product/ProductLists";
import EditProduct from "./Pages/Product/EditProduct";
import PendingLoan from "./Pages/LoanApplication/Pending/PendingLoan";
import ChangeLoanStatus from "./Pages/LoanApplication/ChangeStatus/ChangeStatus";
import ChangePassword from "./Pages/Account/ChangePassword/Password";
import BusinessType from "./Pages/BusinessType/BusinessType";
import RdPlanList from "./Pages/RdPlan/rdPlanList";
import RDPlanAdd from "./Pages/RdPlan/AddRdPlan";
import SlabSettingList from "./Pages/SlabSetting/SlabSettingList.jsx";
import EditSlabSetting from "./Pages/SlabSetting/EditSlabSetting.jsx";
import ForgotPassword from "./Pages/Account/ForgotPassword/ForgotPassword.js";

function App() {
  let x = sessionStorage.getItem(".frontend.authToken");
  const [pageLoading, setPageLoading] = useState(false);

  if (!x) {
    return (
      <>
        <div className="theme-purple">
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </div>
      </>
    );
  }
  return (
    <div className="theme-purple">
      <ToastContainer />
      <BrowserRouter>
        {pageLoading && <PageLoader pageLoading={pageLoading} />}
        <TopHeader setPageLoading={setPageLoading} />
        <TopSubHeader />
        <Routes>
          <Route path="/" element={<Dashboard setPageLoading={setPageLoading} />} />
          <Route path="/Dashboard" element={<Dashboard setPageLoading={setPageLoading} />} />
          <Route path="/SignOut" element={<Logout />} />
          <Route path="/FranchiseSignUp" element={<Signup />} />
          <Route path="/InvestorSignUp" element={<InvestorSignup />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />

          <Route
            path="/User-list"
            element={<List setPageLoading={setPageLoading} />}
          />
          <Route
            path="/InvestorList"
            element={<InvestorList setPageLoading={setPageLoading} />}
          />
          <Route
            path="/Ledger"
            element={<Ledger setPageLoading={setPageLoading} />}
          />

          <Route
            path="/LoanApplicationList"
            element={<LoanList setPageLoading={setPageLoading} />}
          />
          <Route path="/AddLoanApplication" element={<AddLoan setPageLoading={setPageLoading} />} />

          <Route
            path="/EditRangeFee"
            element={<EditRangeFee setPageLoading={setPageLoading} />}
          />
          <Route
            path="/EditRangeFee/:RangeId"
            element={<EditRangeFee setPageLoading={setPageLoading} />}
          />
          <Route
            path="/GetRangeWiseFee"
            element={<GetRangeWiseFee setPageLoading={setPageLoading} />}
          />
          <Route
            path="/CustomerList"
            element={<CustomerList setPageLoading={setPageLoading} />}
          />
          <Route
            path="/LedgerReportList"
            element={<LedgerReportList setPageLoading={setPageLoading} />}
          />
          <Route
            path="/CategoriesList"
            element={<CategoriesList setPageLoading={setPageLoading} />}
          />
          <Route
            path="/EditCategories"
            element={<EditCategories setPageLoading={setPageLoading} />}
          />
          <Route
            path="/EditCategories/:CategoryId"
            element={<EditCategories setPageLoading={setPageLoading} />}
          />
          <Route
            path="/ProductLists"
            element={<ProductLists setPageLoading={setPageLoading} />}
          />
          <Route
            path="/EditProduct"
            element={<EditProduct setPageLoading={setPageLoading} />}
          />
          <Route
            path="/EditProduct/:Id"
            element={<EditProduct setPageLoading={setPageLoading} />}
          />
          <Route
            path="/PendingLoanApplication"
            element={<PendingLoan setPageLoading={setPageLoading} />}
          />
          <Route
            path="/ChangeLoanStatus"
            element={<ChangeLoanStatus setPageLoading={setPageLoading} />}
          />
          <Route
            path="/BusinessType"
            element={<BusinessType setPageLoading={setPageLoading} />}
          />
           <Route
            path="/RDPlanList"
            element={<RdPlanList setPageLoading={setPageLoading} />}
          />
           <Route
            path="/RDPlanAdd"
            element={<RDPlanAdd setPageLoading={setPageLoading} />}
          />
          <Route
            path="/SlabSetting"
            element={<SlabSettingList setPageLoading={setPageLoading} />}
          />
          <Route
            path="/AddSlabSetting"
            element={<EditSlabSetting setPageLoading={setPageLoading} />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
