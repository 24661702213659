import React from "react";
import LoginService from "./LoginService";
import { ButtonSpinner } from "../../../Components/Loader/spinner";
import { Link } from "react-router-dom";

const Login = () => {
  const {
    showPassword,
    setShowPassword,
    isLoading,
    errorObj,
    handleChange,
    handleLogin,
  } = LoginService();
  return (
    <>
      <div className="login-4">
        <div className="container-fluid">
          <div className="row login-box">
            <div className="col-lg-6 form-section">
              <div className="form-inner">
                <a href="login-4.html" className="logo">
                  <img src="/assets/images/whitelogo.png" alt="logo" />
                </a>
                <h3>Sign Into Your Account</h3>
                <form onSubmit={handleLogin}>
                  <div className="form-group position-relative clearfix">
                    <input
                      name="emailId"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                    />
                    <div
                      className="login-popover login-popover-abs"
                      title="Email Address"
                    >
                      <i className="fa fa-info-circle"></i>
                    </div>
                    <span className="vaidation-error">{errorObj?.emailId}</span>
                  </div>
                  <div className="form-group clearfix position-relative password-wrapper">
                    <div className="password-toggle">
                      <input
                        type={showPassword ? "text" : "password"}
                        onChange={handleChange}
                        name="password"
                        className="form-control"
                        autocomplete="off"
                        placeholder="Password"
                      />
                      <div
                        className="password-toggle-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        )}
                      </div>
                    </div>
                    <span className="vaidation-error">
                      {errorObj?.password}
                    </span>
                  </div>
                  <div className="form-group mb-0 d-flex justify-content-between align-items-center">
                    {isLoading ? (
                      <ButtonSpinner
                        isLoading={isLoading}
                        customClass="spinner-button"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-theme"
                      >
                        Login
                      </button>
                    )}
                    <Link to="/ForgotPassword" className="text-white">
                      Forgot Password ?
                    </Link>
                  </div>
                  <div className="extra-login clearfix">
                    <span>Don't have an account? </span>
                  </div>
                  <div className="clearfix"></div>
                </form>
                <div className="clearfix"></div>
                <p>
                  <a href="#" className="thembo">
                    {" "}
                    Register here
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 bg-img clip-home h-100"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
