import { useState } from "react";
import { Link } from "react-router-dom";

const TopSubHeader = () => {

  const [dropDown, setDropDown] = useState('');

  return (
    <>
      <aside id="leftsidebar" className="sidebar h_menu hide-on-print-screen">
        <div className="container">
          <div className="row clearfix">
            <div className="col-12">
              <div className="menu">
                <ul className="list">
                  <li className="header">MAIN</li>
                  <li className="active open">
                    <Link to="/">
                      <i className="fa fa-tachometer" aria-hidden="true"></i>
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  {/* <li className="">
                    <a href="javascript:void(0);" className="menu-toggle">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <span>User Management</span>
                    </a>
                    <ul className="ml-menu">
                      <li className="user-manage-submenu-toggle">
                        <a href="#" className="">
                          User List
                        </a>
                        <ul className="user-manage-submenu">
                          <li className="">
                            <Link to="/User-list">Franchise List</Link>
                          </li>
                          <li className="">
                            <Link to="/InvestorList">Investor List</Link>
                          </li>
                          <li className="">
                            <Link to="/CustomerList">Customer List</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="user-manage-submenu-toggle">
                        <a href="#" className="">
                          Add
                        </a>
                        <ul className="user-manage-submenu">
                          <li className="">
                            <Link to="/FranchiseSignUp">Franchise</Link>
                          </li>
                          <li className="">
                            <Link to="/InvestorSignUp">Investor</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li> */}
                  <li className="">
                    <a href="javascript:void(0);" className="menu-toggle" >
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <span>User Management</span>
                    </a>
                    <ul className="ml-menu" onMouseLeave={() => setDropDown('')}>
                      <li className="">
                        <a href="#" onMouseEnter={() => setDropDown('user-list')}>
                          User List
                        </a>
                        {
                          dropDown == 'user-list' ? (
                            <ul>
                              <li className="">
                                <Link to="/User-list">Franchise List</Link>
                              </li>
                              <li className="">
                                <Link to="/InvestorList">Investor List</Link>
                              </li>
                              <li className="">
                                <Link to="/CustomerList">Customer List</Link>
                              </li>
                            </ul>
                          ) : ''
                        }
                      </li>

                      <li className="">
                        <a href="#" onMouseEnter={() => setDropDown('add-user')}>
                          Add
                        </a>
                        {
                          dropDown == 'add-user' ? (
                            <ul>
                              <li className="">
                                <Link to="/FranchiseSignUp">Franchise</Link>
                              </li>
                              <li className="pb-2">
                                <Link to="/InvestorSignUp">Investor</Link>
                              </li>
                            </ul>
                          ) : ""
                        }
                      </li>
                    </ul>
                  </li>
                  <li className="">
                    <a href="javascript:void(0);" className="menu-toggle">
                      <i className="fa fa-tag"></i>
                      <span>Report</span>
                    </a>
                    <ul className="ml-menu">
                      <li className="">
                        <Link to="/Ledger">Ledger</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="">
                    <a href="javascript:void(0);" className="menu-toggle">
                      <i className="fa fa-tag"></i>
                      <span>Report</span>
                    </a>
                    <ul className="ml-menu">
                      <li className="">
                        <Link to="/LedgerReportList">Ledger</Link>
                      </li>
                    </ul>
                  </li> */}

                  <li className="">
                    <a href="javascript:void(0);" className="menu-toggle">
                      <i className="fa fa-sticky-note-o" aria-hidden="true"></i>
                      <span>Loan Application</span>
                    </a>
                    <ul className="ml-menu">
                      <li className="">
                        <Link to="/LoanApplicationList">Application List</Link>
                      </li>
                      {/* <li className="">
                        <Link to="/AddLoanApplication">New Application</Link>
                      </li> */}
                      <li className="">
                        <Link to="/PendingLoanApplication">Pending Application</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="">
                    <a href="javascript:void(0);" className="menu-toggle">
                      <i className="fa fa-list"></i>
                      <span>Catalogue</span>
                    </a>
                    <ul className="ml-menu">
                      <li className="">
                        <Link to="/CategoriesList">Category</Link>
                      </li>
                      <li className="">
                        <Link to="/ProductLists">Product</Link>
                      </li>
                      <li className="">
                        <Link to="/RDPlanList">RD Plan</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="">
                    <a href="javascript:void(0);" className="menu-toggle">
                      <i className="fa fa-cog"></i>
                      <span>Settings</span>
                    </a>
                    <ul className="ml-menu">
                      <li className="">
                        <Link to="/GetRangeWiseFee">Processing Fee</Link>
                      </li>
                      <li className="">
                        <Link to="/SlabSetting">Slab Setting</Link>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="">
                    <Link to="/GetRangeWiseFee">
                      <i className="fa fa-money"></i>
                      <span>Processing Fee</span>
                    </Link>
                  </li> */}
                  <li className="">
                    <Link to="/BusinessType">
                      <i className="fa fa-server"></i>
                      <span>Business Type</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
export default TopSubHeader;
