import axios from "axios";
import { DataConstant } from "./DataConstant";
import { toast } from "react-toastify";

const baseURL = DataConstant.BASE_URL;

const getStoredAuthToken = () => {
  let c = sessionStorage.getItem(".frontend.authToken");
  return c ?? "";
};

function getHeaders() {
  return {
    accept: "application/json",
    authorization: `Bearer ${getStoredAuthToken()}`,
  };
}

export const postRequest = async (
  endpoint,
  data = null,
  contentType = "application/json",
  params = null
) => {
  let _url = `${endpoint}`;
  if (params) {
    _url += `?${new URLSearchParams(params).toString()}`;
  }
  var config = {
    method: "post",
    url: baseURL + _url,
    headers: {
      "Content-Type": contentType,
      authorization: `Bearer ${getStoredAuthToken()}`,
    },
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (err) {
      if (err.response?.status === 401) {
        sessionStorage.setItem(".frontend.authToken", "");
        toast.error("Your session has been expired. please Login again", {
          toastId: "session-expired",
        });
      } else {
        console.error(`Error in post request to endpoint ${endpoint}`, err);
      }
    });
};

export const getRequest = async (endpoint, data = null) => {
  let _url = `${baseURL + endpoint}`;
  if (data) {
    _url += `?${new URLSearchParams(data).toString()}`;
  }
  try {
    const res = await axios.get(_url, {
      headers: getHeaders(),
    });
    return res.data;
  } catch (err) {
    if (err.response?.status === 401) {
      sessionStorage.setItem(".frontend.authToken", "");
      toast.error("Your session has been expired. please Login again");
    } else {
      console.log(`Error in get request to endpoint ${endpoint}`, err);
    }
    throw err;
  }
};
