import React from "react";
import CustomerListService from "./CustomerListService";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const CustomerList = ({setPageLoading}) => {
  const { data, columns, getCustomerList, searchMobileNumber } =
    CustomerListService(setPageLoading);

  useEffect(() => {
    const mobileNumber = document.getElementById("searchInput").value;
    if (mobileNumber === "") {
      getCustomerList("");
    } else {
      getCustomerList(mobileNumber);
    }
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Customer List</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item">Customer List</li>

                          <li className="breadcrumb-item active">
                            Customer List
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix cus-height">
            <div className="col-lg-12">
              <div className="card">
                <div className="header pb-0">
                  <div className="row">
                    <div className="col-md-9">
                      {/* <h2>
                        <strong>Customer List</strong>
                      </h2> */}
                    </div>
                    <div className="col-md-3">
                      <div className="">
                        {/* <label className="lbl" htmlFor="searchInput">
                          Search
                        </label> */}
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            name="mobileNo"
                            placeholder="Search Here"
                          />
                          <div
                            className="input-group-append cursor-pointer-class"
                            onClick={searchMobileNumber}
                          >
                            <span className="input-group-text">
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="body cus-table">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    keyField="objectId"
                    responsive
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20]}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 215px)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomerList;
