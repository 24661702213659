import React from "react";
import DataTable from "react-data-table-component";
import ListService from "./ListService";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const List = ({ setPageLoading }) => {
  const { userColumns, userList, show, handleClose, handleAmountChange, data, transferFunds } = ListService(setPageLoading);
  return (
    <section className="content">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <h2>Franchise List</h2>
                      <ul className="breadcrumb p-l-0 p-b-0 ">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="fa fa-home"></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">User Management</li>
                        <li className="breadcrumb-item active">
                          Franchise List
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                      <div className="btn-group float-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="dropdown"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle px-3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div
                          className="dropdown-menu cus-toggle"
                          style={{ top: "5px!important" }}
                        >
                          <Link className="dropdown-item" to="/FranchiseSignUp">
                            Franchises
                          </Link>
                          <Link className="dropdown-item" to="/InvestorSignUp">
                            Investors
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix cus-height">
          <div className="col-lg-12">
            <div className="card">
              {/* <div className="header">
                <div className="row">
                  <div className="col-md-9">
                    <h2>
                      <strong>Franchise List</strong>
                    </h2>
                  </div>
                </div>
              </div> */}
              <div className="body cus-table mt-2 px-0 px-md-3">
                <DataTable
                  columns={userColumns}
                  data={userList}
                  pagination
                  keyField="objectId"
                  responsive
                  className="custom-table-overflow"
                  // custom-table-overflow table-responsive
                  // expandableRows
                  // expandableRowsComponent={ExpandableComponent}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20]}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 215px)"
                />
              </div>
            </div>
          </div>
        </div>
          <Modal show={show} onHide={handleClose} className="cus-modal-center" centered>
          <div className="bg-primary text-white d-flex">
            <Modal.Title className="text-white my-2 mx-4">
              Collect funds
            </Modal.Title>
            <span aria-hidden="true" onClick={handleClose} className="modal-cross pt-2">
              &times;
            </span>
          </div>
          <Modal.Body className="pb-1">
            <div className="form-group">
              <input
                name="amount"
                onChange={handleAmountChange}
                placeholder="Please Enter Your Amount"
                value={data?.amount}
                className="form-control"
              ></input>
              <input
                name="remark"
                onChange={handleAmountChange}
                placeholder="Please Enter Your Remark"
                value={data?.remark}
                className="form-control mt-3"
              ></input>
            </div>
          </Modal.Body>
          <div className="container pb-2">
            <Button className="btn-primary" variant="secondary" onClick={transferFunds}>
              Collect
            </Button>
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default List;
