import React from "react";
import { Link } from "react-router-dom";
import BusinessTypeService from "./BusinessTypeService";
import 'react-loading-skeleton/dist/skeleton.css'
import BtRdList from "./BtRdList";
import BtLoanList from "./BtLoanList";

const BusinessType = ({ setPageLoading }) => {

    const {
        handleGetInfo,
        applicationList,
        handleChange,
        EMIData,
        handleCardClick,
        tableColumns,
        tableLoader,
        rdList,
        inputValues,
        accountStatement,
        accountStatementColumns,
        selectedCard,
        selectedCardRd,
    } = BusinessTypeService(setPageLoading);

    return (
        <section className="content">
            <div className="container">
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="body ">
                                <div className="block-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-8 col-sm-12">
                                            <h2>Business Type</h2>
                                            <ul className="breadcrumb p-l-0 p-b-0 ">
                                                <li className="breadcrumb-item">
                                                    <Link to="/">
                                                        <i className="fa fa-home"></i>
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    Business Type
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix cus-height">
                    <div className="col-lg-12">
                        <div className="card px-md-3">
                            <div className="body">
                                <div className="row align-items-center">
                                    <div className="col-md-6 px-2">
                                        <label>Business Type: </label>
                                        <input type="radio" className="ml-3"onChange={handleChange} name="filterTypeLoan" value={1} />
                                        <span className="mx-1">RD</span>
                                        <input className="ml-3" onChange={handleChange} type="radio" name="filterTypeLoan" value={2} />
                                        <span>Loan</span>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="form-control px-3 input-height" onChange={handleChange} type="text" name="mobile" placeholder="Please enter mobile" />
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <button className="btn btn-info btn-block" onClick={handleGetInfo}>
                                            Get info
                                        </button>
                                    </div>
                                </div>
                                {
                                    inputValues?.filterTypeLoan === '2' && applicationList?.length > 0 ? (
                                        <BtLoanList
                                            applicationList={applicationList}
                                            handleGetInfo={handleGetInfo}
                                            handleCardClick={handleCardClick}
                                            tableLoader={tableLoader}
                                            tableColumns={tableColumns}
                                            EMIData={EMIData}
                                            selectedCard={selectedCard}
                                        />
                                    ): inputValues?.filterTypeLoan === '1' && rdList?.length > 0 ? (
                                        <BtRdList
                                            rdList={rdList}
                                            handleCardClick={handleCardClick}
                                            tableLoader={tableLoader}
                                            accountStatement={accountStatement}
                                            accountStatementColumns={accountStatementColumns}
                                            selectedCardRd={selectedCardRd}
                                        />
                                    ): (
                                        <div className="p-3 d-flex justify-content-center">
                                            No records found
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BusinessType;