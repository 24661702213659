import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { LoanStatus, SearchType } from "../../../Services/interface";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoanListService from "./LoanListService";
const LoanList = ({ setPageLoading }) => {
  const {
    applicationColumns,
    tableColumns,
    getApplicationList,
    handleApprovedChange,
    handleFromDateChange,
    handleToDateChange,
    handleClose,
    applicationList,
    show,
    fromDate,
    toDate,
    setApiStatus,
    showForEMI,
    handleClose2,
    EMIList,
    setSearchType,
    setSearchKeyword,
    setRemark,
  } = LoanListService(setPageLoading);
  return (
    <section className="content">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body ">
                <div className="block-header">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <h2>Loan Applications</h2>
                      <ul className="breadcrumb p-l-0 p-b-0 ">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="fa fa-home"></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Applications List
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                      <Link to="/AddLoanApplication">
                        <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                          New Applications
                        </button>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix cus-height">
          <div className="col-lg-12">
            <div className="card">
              <div className="header loan-application pt-0">
                {/* <div class="row">
                  <div class="col-md-9">
                    <h2>
                      <strong>Loan Application</strong>
                    </h2>
                  </div>
                </div> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="lbl" htmlFor="fromDate">
                          From Date
                        </label>
                        <DatePicker
                          type="date"
                          className="form-control form-selection"
                          id="fromDate"
                          name="fromDate"
                          selected={fromDate}
                          onChange={handleFromDateChange}
                          dateFormat="dd MMMM yyyy"
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2" id="asdf">
                        <label className="lbl" htmlFor="toDate">
                          To Date
                        </label>
                        <DatePicker
                          type="date"
                          className="form-control form-selection"
                          id="toDate"
                          name="toDate"
                          selected={toDate}
                          onChange={handleToDateChange}
                          dateFormat="dd MMMM yyyy"
                          autoComplete="off"
                        />
                        <small
                          id="Small4"
                          className="form-text text-muted d-none"
                        >
                          {" "}
                          {/* {selectedToDate} */}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-2 form-group">
                        <label className="lbl" htmlFor="ddlStatus">
                          Choose Status
                        </label>
                        <select
                          id="ddlStatus"
                          tabIndex="2"
                          className="custom-select"
                          data-val="true"
                          data-val-required="The Flag field is required."
                          name="Flag"
                          onChange={(e) => {
                            setApiStatus(+e.target.value);
                          }}
                        >
                          <option value="-1" selected="selected">
                            :: ALL ::
                          </option>
                          <option value="0">{LoanStatus[0]}</option>
                          <option value="1">{LoanStatus[1]}</option>
                          <option value="2">{LoanStatus[2]}</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                      <div className="mb-2">
                        <label className="lbl" htmlFor="ddlCriteria">
                          Choose Criteria
                        </label>
                        <select
                          id="ddlCriteria"
                          tabIndex="3"
                          className="form-control"
                        >
                          <option value="0">:: Select Criteria ::</option>
                          <option value="2">Account No.</option>
                          <option value="4">Transaction ID</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="col-md-2">
                      <div className="mb-2">
                        <label className="">
                          Mobile Type
                        </label>
                        <select
                          className="form-control"
                          // name="searchType"
                          onChange={(e) => {
                            setSearchType(+e.target.value);
                          }}
                        >
                          <option value="0">:: Select Search Type ::</option>
                          {/* <option value="0">{SearchType[0]}</option> */}
                          <option value="1">{SearchType[1]}</option>
                          <option value="2">{SearchType[2]}</option>
                          <option value="3">{SearchType[3]}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-2">
                        <label className="lbl" htmlFor="txtSearch">
                          Search
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="txtSearch"
                            name="search"
                            onChange={(e) => {
                              setSearchKeyword(e.target.value);
                            }}
                            placeholder="Search Here"
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text cursor-pointer-class"
                              onClick={() => {
                                getApplicationList();
                              }}
                              >
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body">
                <DataTable
                  // title="Site List"
                  columns={applicationColumns}
                  data={applicationList}
                  pagination
                  keyField="objectId"
                  responsive
                  // expandableRows
                  // expandableRowsComponent={ExpandableComponent}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20]}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 215px)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>    
      {/* // loan application approval status */}
      <Modal show={show} size="sm" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mt-3" style={{ marginLeft: "42px" }}>
            Confirmation
          </Modal.Title>
          <span
            aria-hidden="true"
            onClick={handleClose}
            className="modal-cross"
          >
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className="pt-0">
          Aye you sure you want to proceed!
        </Modal.Body>
        <textarea
          name="comments"
          className="cus-comments"
          id="comments"
          onChange={(e) => setRemark(e.target.value)}
          placeholder="Please Enter Your Remark"
        ></textarea>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleApprovedChange}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
        {/* modal for EMI list */}
      <Modal show={showForEMI} size="lg" onHide={handleClose2}>
        <Modal.Header className="cus-emi-data">
          <Modal.Title className="mt-3 cus-emi-title">EMI Data</Modal.Title>
          <span
            aria-hidden="true"
            onClick={handleClose2}
            className="modal-cross text-light"
          >
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className="">
          <div className="body">
            <DataTable
              // title="Site List"
              columns={tableColumns}
              data={EMIList}
              pagination
              keyField="objectId"
              responsive
              // expandableRows
              // expandableRowsComponent={ExpandableComponent}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20]}
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight="calc(100vh - 215px)"
            />
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default LoanList;
