import { useState } from "react";
import { postRequest } from "../../Services/apiServices";
import moment from "moment";
import { toast } from "react-toastify";
import { TransactionType } from "../../Services/interface";

const BusinessTypeService = (setPageLoading) => {

    const [applicationList, setApplicationList] = useState([]);
    const [rdList, setRDList] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [EMIData, setEMIdata] = useState([]);
    const [accountStatement, setAccountStatement] = useState([]);
    const [tableLoader, setTableLoader] = useState(false);
    const [selectedCard, setSelectedCard] = useState("");
    const [selectedCardRd, setSelectedCardRd] = useState("");

    const tableColumns = [
        {
            name: "#",
            width: "40px",
            cell: (row, index) => {
                return (
                    <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
                );
            },
        },
        {
            name: "Schedule",
            sortable: true,
            cell: (row) => {
                return row?.scheduleOn;
            },
        },
        {
            name: "Paid On",
            width: "125px",
            sortable: true,
            cell: (row) => {
                return (
                    row?.paidOn ? row?.paidOn : (
                        new Date(row?.scheduleOn) > new Date() ? (
                            <span>N/A</span>
                        ) : <span>Bounce</span>
                    )
                );
            },
        },
        {
            name: <div className="cus-inr-text-header">Amount</div>,
            width: "125px",
            cell: (row) => {
                return (
                    <div className="cus-inr-text-value">
                        <i className="fa fa-inr text-success">
                        </i> {" "}{row?.amount}
                    </div>
                );
            },
        },
    ];

    const accountStatementColumns = [
        {
            name: "#",
            width: "40px",
            cell: (row, index) => {
                return (
                    <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
                );
            },
        },
        {
            name: "Customer Name",
            sortable: true,
            cell: (row) => {
                return row?.customerName;
            },
        },
        {
            name: "Customer Email",
            sortable: true,
            cell: (row) => {
                return (
                    row?.customerEmailId
                );
            },
        },
        {
            name: "Customer Mobile",
            sortable: true,
            cell: (row) => {
                return (
                    row?.customerMobile
                );
            },
        },
        {
            name: "Customer Address",
            sortable: true,
            cell: (row) => {
                return (
                    row?.customerAddress
                );
            },
        },
        {
            name: <div className="cus-inr-text-header">Opening</div>,
            width: "125px",
            cell: (row) => {
                return (
                    <div className="cus-inr-text-value">
                        <i className="fa fa-inr text-success">
                        </i> {" "}{row?.opening}
                    </div>
                );
            },
        },
        {
            name: <div className="cus-inr-text-header">Amount</div>,
            width: "125px",
            cell: (row) => {
                return (
                    <div className="cus-inr-text-value">
                        <i className="fa fa-inr text-success">
                        </i> {" "}{row?.amount}
                    </div>
                );
            },
        },
        {
            name: <div className="cus-inr-text-header">Closing</div>,
            width: "125px",
            cell: (row) => {
                return (
                    <div className="cus-inr-text-value">
                        <i className="fa fa-inr text-success">
                        </i> {" "}{row?.closing}
                    </div>
                );
            },
        },
        {
            name: "Tr Type",
            sortable: true,
            cell: (row) => {
                return (
                    <span class={"badge " + (TransactionType[row?.trType] === "Credited" ? 'bg-soft-success text-success' : 'bg-soft-danger text-danger')}>
                        {TransactionType[row?.trType]}
                    </span>
                );
            },
        },
        {
            name: "Service Name",
            sortable: true,
            cell: (row) => {
                return (
                    row?.serviceName
                );
            },
        },
    ];

    const handleGetInfo = async (e) => {
        try {
            setSelectedCard("");
            setSelectedCardRd("");
            if (!inputValues?.filterTypeLoan === true) {
                return toast.error("Please select business type", { toastId: "Business-Type-invalid" });
            }
            setPageLoading(true);
            if (inputValues?.filterTypeLoan === '2') {
                let fromDate = '1 Jul 2023';
                const resData = await postRequest("LoanApplication/GetLoanApplications", {
                    status: e?.target?.value || e.target.value === "0" ? +e.target.value: -1,
                    textSearch: inputValues?.mobile || '',
                    searchType: inputValues?.mobile ? 3: 0,
                    toDate: moment(new Date()).format("DD MMM YYYY"),
                    fromDate: moment(fromDate).format("DD MMM YYYY"),
                    loginId: 0,
                });
                if (resData) {
                    setApplicationList(resData);
                }
            } else {
                const resData = await postRequest("RDService/GetApplications", {
                    "loginId": 0,
                    "mobileNo": inputValues?.mobile || ""
                });
                if (resData?.statusCode === 1) {
                    setRDList(resData?.result);
                }
            }
            setPageLoading(false);
        } catch (error) {
            setPageLoading(false);
            toast.error("Failed to delete category");
            console.log(error);
        }
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setInputValues((old) => {
            return {
                ...old,
                [name]: value
            }
        })
    }

    const handleCardClick = async (e, loanId, isRd) => {
        try {
            setTableLoader(true);
            if (isRd) {
                setSelectedCardRd(loanId);
                const resData = await postRequest(
                    `RDService/GetAccountStatements/${loanId}`,
                    );
                if (resData?.statusCode === 1) {
                    setAccountStatement(resData.result);
                }
            } else {
                setSelectedCard(loanId);
                const resData = await postRequest(
                    "LoanApplication/GetEMIRes",
                    false,
                    false,
                    {
                        LoanId: loanId,
                    }
                );
                if (resData?.statusCode === 1) {
                    setEMIdata(resData.result);
                }
            }
            setTableLoader(false);
        } catch (error) {
            setTableLoader(false);
            console.log(error);
        }
    }

    return {
        handleGetInfo,
        applicationList,
        handleChange,
        EMIData,
        handleCardClick,
        tableColumns,
        tableLoader,
        rdList,
        inputValues,
        accountStatement,
        accountStatementColumns,
        selectedCard,
        selectedCardRd,
    }
}

export default BusinessTypeService