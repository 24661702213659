import React, { useState, useEffect } from "react";
import { DataConstant } from "../../../../Services/DataConstant";
import { postRequest } from "../../../../Services/apiServices";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ListService = (setPageLoading) => {
  const [userList, setUserList] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const handleClose = () => setShow(false);
  const [data, setData] = useState({});

  async function getInvestorList() {
    try {
      setPageLoading(true);
      var postResponse = await postRequest(DataConstant.INVESTORLIST, {});
      if (postResponse?.statusCode === 1) {
        setUserList(postResponse?.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  const handleAmountChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleAmountsChange = (e) => {
    const numericValue = e.target.value.replace(/\D/, "");
    setData({ ...data, [e.target.name]: numericValue });
  };

  const userColumns = [
    {
      name: "#",
      width: "40px",
      cell: (row, index) => {
        return (
          <div style={{ padding: "0 1px", flex: "none" }}>{index + 1}</div>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
          <div className="custom-new-toggle-div">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
            ></button>
            <div className="dropdown-menu cus-toggle custom-new-toggle">
              <div className="cus-drop-down-list px-3">
                <Link
                  to="/InvestorSignUp"
                  className="py-2"
                  state={{ InvestorData: row }}
                >
                  Edit
                </Link>
              </div>
              <div
                className="cus-drop-down-list px-3 py-2"
                onClick={() => {
                  setShow(true);
                  setId(row.userID);
                }}
              >
                <span className="">Transfer Funds</span>
              </div>
              <div className="cus-drop-down-list px-3">
                <Link to="/Ledger" className="py-2" state={{ mobileNo: row?.mobileNumber }}>
                  Ledger
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="custom-new-toggle-div">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
            >
            </button>
            <div
              className="dropdown-menu cus-toggle custom-new-toggle"
            >
              <div className="cus-drop-down-list px-3 py-2">
                <Link to="/InvestorSignUp" className="" state={{ InvestorData: row }}>
                  Edit
                </Link>
              </div>
              <div className="cus-drop-down-list px-3 py-2"
                onClick={() => {
                  setShow(true);
                  setId(row.userID);
                }}>
                <span
                  className=""
                >
                  Transfer Funds
                </span>
              </div>
            </div>
          </div> */}
          </>
        );
      },
    },
    {
      name: "Name",
      sortable: true,
      cell: (row) => {
        return row?.name;
      },
    },
    {
      name: "Mobile Number",
      cell: (row) => {
        return row?.mobileNumber;
      },
    },
    {
      name: <div className="cus-inr-text-header">Balance</div>,
      // sortable: true,
      // sortFunction: (a, b) => parseInt(a.amount) - parseInt(b.amount),
      cell: (row) => {
        return (
          <div className="cus-inr-text-value">
            <i className="fa fa-inr text-success">
            </i> {" "}{row?.amount}
          </div>
        )
      },
    },
    {
      name: "Aadhar Number",
      cell: (row) => {
        return row?.adhar;
      },
    },
    {
      name: "Pan Number",
      cell: (row) => {
        return row?.pan;
      },
    },
    {
      name: "Email ID",
      cell: (row) => {
        return row?.emailId;
      },
    },
    {
      name: "Address",
      cell: (row) => {
        return row?.address;
      },
    },
    {
      name: "Postal code",
      cell: (row) => {
        return row?.postalCode;
      },
    },
    // {
    //   name: "Action",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <Link to="/InvestorSignUp" state={{ InvestorData: row }}>
    //           <i title="Edit" className="fa fa-edit fa-lg text-info"></i>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
    // {
    //   name: "Transfer Funds",
    //   sortable: true,
    //   cell: (row) => {
    //     return (
    //       <>
    //         <Button
    //           className="btn btn-primary"
    //           onClick={() => {
    //             setShow(true);
    //             setId(row.userID);
    //           }}
    //         >
    //           Transfer Funds
    //         </Button>
    //       </>
    //     );
    //   },
    // },
  ];

  async function transferFunds() {
    if (!data?.amount || !data?.remark) {
      return toast.error("Invalid Input", { toastId: "invalid-amount" });
    }
    try {
      setPageLoading(true);
      var postResponse = await postRequest("Fund/Transfer", {
        ...data,
        userId: id,
        trType: "c",
      });
      if (postResponse.statusCode === 1) {
        toast.success("Funds transferred successfully");
        getInvestorList();
        handleClose();
        setData({});
      } else {
        toast.error(postResponse.responseText || "Failed to transfer funds");
      }
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
      toast.error("Something went wrong, while transferring funds");
    }
  }

  useEffect(() => {
    getInvestorList();
  }, []);

  return {
    userList,
    show,
    handleAmountChange,
    handleAmountsChange,
    userColumns,
    transferFunds,
    handleClose,
    data,
  };
};

export default ListService;
