import React from "react";
import { useState } from "react";
import { getRequest, postRequest } from "../../Services/apiServices";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { Capitalize } from "../../Services/Common";
import { Modal } from "react-bootstrap";

const ProductService = (setPageLoading) => {
  const initialState = {
    categoryId: "",
    productTitle: "",
    productName: "",
    quantity: "",
    mrp: "",
    purchaseCost: "",
    sellingPrice: "",
    franchiseCommision: "",
    imageData: "",
    isPublished: true,
    productDescription: "",
  }
  const [getProductList, setProductList] = useState([]);
  const [input, setInput] = useState(initialState);
  const [errorObj, setErrorObj] = useState({});
  const [image, setImage] = useState();
  const param = useParams();
  const navigate = useNavigate();
  const [getId, setGetId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [getCategoryIdValue, setCategoryIdValue] = useState("");
  const [statusValue, setStatusValue] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handlePublish = async (e, productId, isPublished) => {
    try {
      setPageLoading(true);
      let obj = {
        "productId": productId,
        "isPublish": isPublished ? false : true
      }
      const res = await postRequest(`Master_Product/ChangeStatus`, obj);
      await getProductByCategoryId();
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText || 'Failed to change product status');
      }
      setPageLoading(false);
    } catch (error) {
      toast.error('Failed to change product status');
      setPageLoading(false);
    }
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "80px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "120px",
    },
    {
      name: "TITLE",
      selector: (row) => row.productTitle,
      width: "150px",
    },
    {
      name: "NAME",
      selector: (row) => row.productName,
      width: "150px",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.productDescription,
      width: "150px",
    },
    {
      name: "IMAGE",
      selector: (row) => row.imageData,
      width: "100px",
    },
    {
      name: "ISPUBLISH",
      selector: (row) => row.isPublished,
      width: "130px",
    },
    {
      name: "QUANTITY",
      selector: (row) => row.quantity,
      width: "100px",
    },
    {
      name: <div className="cus-inr-text-header">PURCHASE COST</div>,
      cell: (row) => row.purchaseCost,
      width: "150px",
    },
    {
      name: <div className="cus-inr-text-header">MRP</div>,
      cell: (row) => row.mrp,
      width: "130px",
    },
    {
      name: <div className="cus-inr-text-header">SELLING PRICE</div>,
      cell: (row) => row.sellingPrice,
      width: "150px",
    },
    {
      name: <div className="cus-inr-text-header">FRANCHISE COMMISION</div>,
      cell: (row) => row.franchiseComm,
      width: "190px",
    },
    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "220px",
    },
  ];

  const data = getProductList?.map((d, i) => ({
    serial: i + 1,
    action: (
      <>
        {/* <Link
          to={{
            pathname: "/EditProduct/" + d?.id,
          }}
        >
          <i title="Edit" className="fa fa-edit fa-2x text-info"></i>
        </Link>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-2x text-danger mx-3"></i>
        </span> */}
        <div className="custom-new-toggle-div">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
          ></button>
          <div className="dropdown-menu cus-toggle custom-new-toggle">
            <div className="cus-drop-down-list px-3">
              <Link className="py-2" 
                to={{
                  pathname: "/EditProduct/" + d?.id,
                }}
              >
                Edit
              </Link>
            </div>
            <div className="cus-drop-down-list px-3">
              <div className="py-2" onClick={() => handlepopup(d?.id)}>
                Delete
              </div>
            </div>
            <div className="cus-drop-down-list px-3">
              <div className="py-2" onClick={(e) => handlePublish(e, d?.id, d?.isPublished)}>
                {d?.isPublished ? "UnPublish": "Publish"}
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    productTitle: d?.productTitle,
    productName: d?.productName,
    productDescription: d?.productDescription,
    imageData: (
      <div className="product-image">
        <div className="thumbnail-container py-1">
          <img src={d?.imageData} alt="Image" width={50} height={50} style={{ cursor: 'pointer' }}
            onClick={() => handleImageClick({src: d?.imageData, alt: "image"})}
            onError={(e) => (
              (e.target.src = "/assets/images/noimage.jpg"),
              (e.target.style.width = "40px"),
              (e.target.style.height = "40px")
            )} 
          />
        </div>
        <Modal show={modalOpen && selectedImage} className="img-larger-modal-cn" onHide={handleCloseModal}>
          <div className="">
            <span className="img-larger-close" onClick={handleCloseModal}>&times;</span>
            <img src={selectedImage?.src} alt={"Image"} 
              onError={(e) => (
                (e.target.src = "/assets/images/noimage.jpg"),
                (e.target.style.width = "300px"),
                (e.target.style.height = "300px")
              )}
            />
          </div>
        </Modal>
      </div>
    ),
    mrp: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success">
          </i> {" "}{d?.mrp}
        </div>
      </>
    ),
    sellingPrice: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success">
          </i> {" "}{d?.sellingPrice}
        </div>
      </>
    ),
    quantity: d?.quantity,
    franchiseComm: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success">
          </i> {" "}{d?.franchiseComm}
        </div>
      </>
    ),
    purchaseCost: (
      <>
        <div className="cus-inr-text-value">
          <i className="fa fa-inr text-success">
          </i> {" "}{d?.purchaseCost}
        </div>
      </>
    ),
    isPublished: d?.isPublished ? (
      <img src="/assets/images/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="assets/images/delete.png" style={{ width: "20px" }} />
    ),
    entryOn: d?.entryOn,
  }));

  /////******GET PRODUCT LIST ******///////

  const productListGet = async () => {
    try {
      setPageLoading(true);
      const res = await getRequest(`Master_Product/GetAllAsync`);
      setPageLoading(false);
      if (res) {
        setProductList(res.result);
      }
    } catch (error) {
      setPageLoading(false);
    }
  };

  /////******GET PRODUCT LIST ******///////

  /////******ADD PRODUCT LIST ******///////

  const handleChange = async (e) => {
    const { name, value, checked } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: name == "isPublished" ? checked : value };
    });
    setErrorObj((prev) => {
      return {
        ...prev,
        [name]: value ? "": Capitalize(name) + " is required"
      }
    })
  };

  const handleImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["imageData"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
    setErrorObj((prev) => {
      return {
        ...prev,
        ["imageData"]: ""
      }
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let hasError = false;
      // Check all form fields for errors
      Object.keys(input).forEach((name) => {
        const value = input[name];
        if (value === "" || value === null) {
          setErrorObj((old) => ({
            ...old,
            [name]: Capitalize(name) + " is required",
          }));
          hasError = true;
        }
      });
      if (hasError) {
        return;
      }
      setIsLoading(true);
      let res = {};
      let formData = new FormData();
      let _d = { ...input };
      _d.imageData = image?.value;
      if (!param?.Id) {
        _d.Id = 0;
      } else {
        _d.Id = param?.Id
      }
      _d.franchiseComm = input?.franchiseCommision;
      delete _d.franchiseCommision
      for (let key in _d) {
        formData.append(key, _d[key]);
      }
      res = await postRequest(
        `Master_Product/AddAsync`,
        formData,
        "multipart/form-data"
      );
      if (res?.statusCode !== 1) {
        toast.error(res.responseText || "Failed to add product.");
      } else {
        navigate("/ProductLists");
        toast.success(res.responseText || "Success");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to add product.");
      setIsLoading(false);
    }
  };
  /////******ADD PRODUCT LIST ******///////

  /////******GET BY ID AND EDIT PRODUCT LIST ******///////

  const getByIdProductList = async () => {
    try {
      const res = await postRequest(`Master_Product/GetByIdAsync/${param.Id}`);
      if (res?.statusCode === 1) {
        setInput({
          categoryId: res.result.categoryId,
          productTitle: res.result.productTitle ,
          productName: res.result.productName,
          quantity: res.result.quantity,
          mrp: res.result.mrp,
          purchaseCost: res.result.purchaseCost,
          sellingPrice: res.result.sellingPrice,
          franchiseCommision: res.result.franchiseComm,
          imageData: res.result.imageData,
          isPublished: res.result.isPublished,
          productDescription: res.result.productDescription,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /////******GET BY ID AND EDIT PRODUCT LIST  ******///////

  /////******DELETE BY ID  PRODUCT LIST  ******///////
  const handlepopup = async (Id) => {
    setShowModal(true);
    setGetId(Id);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      setShowModal(false);
      const res = await postRequest(
        `Master_Product/DeleteMasterProductAsync?Id=${getId}`
      );
      if (res?.statusCode === 1) {
        getProductByCategoryId();
        setShowModal(false);
        toast.success(res?.responseText);
      } else {
        toast.error(res?.responseText);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete product");
    }
  };
  /////******DELETE BY ID  PRODUCT LIST  ******///////

  /////******GET PRODUCT BY CATEGORY ID  ******///////

  const handleProdCatChange = (e) => {
    let value = e.target.value;
    setCategoryIdValue(value);
    getProductByCategoryId(value);
  };

  const handleStatusChange = (e) => {
    let { value } = e.target;
    setStatusValue(value);
    getProductByCategoryId(0, value);
  };

  const getProductByCategoryId = async (id, status) => {
    try {
      setPageLoading(true);
      let obj = {
        "isPublish": status || -1,
        "categoryId": id || 0
      }
      const res = await postRequest(
        `Master_Product/GetProductByCategory`,
        obj
      );
      if (res) {
        setProductList(res.result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  };

  /////******GET PRODUCT BY CATEGORY ID  ******///////

  return {
    data,
    columns,
    productListGet,
    handleChange,
    handleSubmit,
    handleImage,
    input,
    errorObj,
    getByIdProductList,
    showModal,
    handleClose,
    handleDelete,
    getCategoryIdValue,
    handleProdCatChange,
    handleStatusChange,
    getProductByCategoryId,
    isLoading,
    statusValue,
  };
};

export default ProductService;


{/* <div className="img-larger-modal">
  <div className="modal-content img-larger-modal-content">
    <span className="img-larger-close" onClick={handleCloseModal}>&times;</span>
    <img src={selectedImage?.src} alt={Image} 
      onError={(e) => (
        (e.target.src = "/assets/images/noimage.jpg"),
        (e.target.style.width = "300px"),
        (e.target.style.height = "300px")
        )} 
        />
  </div>
</div> */}