import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import SlabSettingService from "./SlabSettingService";

const SlabSettingList = ({ setPageLoading }) => {
  const {
    data,
    columns,
    getSlabSettingList,
  } = SlabSettingService({ setPageLoading });

  useEffect(() => {
    getSlabSettingList();
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2>Slab Setting List</h2>
                        <ul className="breadcrumb p-l-0 p-b-0 ">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <i className="fa fa-home"></i>
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            Slab Setting
                          </li>

                          <li className="breadcrumb-item active">
                            Slab Setting List
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 text-right">
                        <Link to="/AddSlabSetting">
                          <button className="btn btn-primary btn-round btn-simple float-right hidden-xs m-l-10">
                            Add New
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix cus-height">
            <div className="col-lg-12">
              <div className="card">
                <div className="body cus-table mt-2">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    keyField="objectId"
                    responsive
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20]}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 215px)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SlabSettingList;
